import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, CalendarioService, PerfilService, LoaderService, DispositivoService, GestionActBiomedidaService } from '../../../../core/services';
import { Actividad } from '../../../../core/models';

import { VistaCalendario } from '../vista-calendario.model';
import { parseISO, isBefore, isEqual } from 'date-fns';


/**
 * Componente para la vista mensual del calendario.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Component({
  selector: 'app-mes',
  templateUrl: 'mes.component.html',
  styleUrls: ['../../calendario.component.css', './mes.component.css']
})
export class MesComponent extends VistaCalendario {
  public fecha: Date;
  public mes: any[][] = [];
  public hoy: number;

  constructor(
    public loader: LoaderService,
    public alertService: AlertService,
    public calendarioService: CalendarioService,
    public perfilService: PerfilService,
    public dispositivoService: DispositivoService,
    public gestionActBiomedidaService: GestionActBiomedidaService,
    translateService: TranslateService

  ) {
    super(alertService, calendarioService, perfilService, dispositivoService,  gestionActBiomedidaService, translateService);

    // Inicialización de los datos.
    const hoy = new Date();
    hoy.setHours(0, 0, 0, 0);
    this.hoy = new Date(+hoy).getTime();
    this.fecha = new Date();

    // Subscripción al evento reload.
    // Se recarga la vista semanal.
    this.subs.push(
      this.calendarioService.reload.subscribe(() => {
        this.fecha = new Date();
        this.cargarActividades();
      })
    );
  }

  public calcularFechaInicial(): void {
    // Cálculo de la fecha inicial.
    const primerDiaAMostrar = new Date(+this.fecha);
    this.fechaIni = new Date(primerDiaAMostrar.getFullYear(), primerDiaAMostrar.getMonth(), 1, 0, 0, 0, 0);
    const primerDiaSemana = (new Date(+this.fechaIni).getDay() || 7) - 1;
    this.fechaIni.setDate(this.fechaIni.getDate() - primerDiaSemana);
  }

  public calcularFechaFinal(): void {
    const fechaFinMes = new Date(this.fechaIni);
    fechaFinMes.setDate(fechaFinMes.getDate() + 42);
    this.fechaFin = new Date(fechaFinMes);
  }

  public actualizarActividades(actividades: Actividad[]): void {
    this.actividadesTratadas = actividades;
    this.prepararMes();
  }

  public restar(): void {
    // Función que cambia la fecha a mostrar a la del mes anterior y carga los datos de ese mes.
    const ahora = new Date();
    const fecha = new Date(this.fecha);
    // Se establece como día el 1 del mes ya que, si el día actual es mayor que 28, el cambio de enero a febrero es incorrecto.
    fecha.setDate(1);
    fecha.setMonth(fecha.getMonth() - 1);
    if (fecha.getMonth() === ahora.getMonth()) {
      fecha.setDate(ahora.getDate());
    }
    this.fecha = fecha;
    this.cargarActividades();
  }

  public sumar(): void {
    // Función que cambia la fecha a mostrar a la del mes posterior y carga los datos de ese mes.
    const ahora = new Date();
    const fecha = new Date(this.fecha);
    // Se establece como día el 1 del mes ya que, si el día actual es mayor que 28, el cambio de enero a febrero es incorrecto.
    fecha.setDate(1);
    fecha.setMonth(fecha.getMonth() + 1);
    if (fecha.getMonth() === ahora.getMonth()) {
      fecha.setDate(ahora.getDate());
    }
    this.fecha = fecha;
    this.cargarActividades();
  }

  private prepararMes(): void {
    this.mes[0] = [];
    let semana = 0;
    let dia = 0;
  
    // Para cada fecha (de fechaIni a fechaFin) se recuperan las actividades y
    // se guarda en la semana y día correspondientes del mes.
    for (const fecha = new Date(+this.fechaIni); fecha.getTime() < this.fechaFin.getTime(); fecha.setDate(fecha.getDate() + 1)) {
      let actividades: Actividad[];
      const fechaInicioDia = new Date(fecha);
      const fechaFinDia = new Date(fecha);
      fechaFinDia.setDate(fecha.getDate() + 1);
  
      actividades = this.actividadesTratadas.filter(
        (a) => {
          const fechaInicio = parseISO(a.fechaInicioSinMargen);
          const fechaFinal = parseISO(a.fechaFinalSinMargen);
          return (
            fechaInicio >= fechaInicioDia &&
            ((isBefore(fechaFinal, fechaFinDia) && isEqual(fechaInicio, fechaFinal)) ||
              (fechaFinal <= fechaFinDia && !isEqual(fechaInicio, fechaFinal)))
          );
        }
      );
  
      const otro = fecha.getMonth() !== this.fecha.getMonth();
      this.mes[semana][dia] = {
        valor: fecha.getTime(),
        dia: fecha.getDate(),
        otro: otro,
        pautadas: actividades.filter((a) => a.estado === 'VACIO' && !a.todoElDia).length > 0,
        todoeldia: actividades.filter((a) => a.todoElDia && a.estado === 'VACIO').length > 0,
        caducadas: actividades.filter(
          (a) => {
            const fechaFinal = parseISO(a.fechaFinalSinMargen);
            return (
              isBefore(fechaFinal, new Date()) &&
              (a.tipo === 'personal' || (a.tipo !== 'personal' && a.estado === 'AUSENCIA'))
            );
          }
        ).length > 0,
        realizadas: actividades.filter((a) => a.estado === 'ADHERIDO').length > 0
      };
  
      // Si es el último día de la semana, se crea una semana más, exceptuando el caso de la semana número 6.
      if (dia / 6 === 1 && semana !== 5) {
        semana++;
        dia = 0;
        this.mes[semana] = [];
      } else {
        dia++;
      }
    }
  }
}
