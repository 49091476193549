<div class="container">
  <loader></loader>
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ dispositivo.descripcion | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>
  <div class="row fechas">
    <div class="col-6">
      <div class="form-group row justify-content-center">
        <label for="desde" class="col-form-label">{{ 'BIOMEDIDAS.HISTORIAL.DESDE' | translate }}</label>
        <div class="datoCol fechaMedida input-group">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            [(ngModel)]="fechaIni"
            (ngModelChange)="fechaChange()"
            ngbDatepicker
            #d="ngbDatepicker"
            id="desde"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button">
              <i class="icon-calendario" style="font-size: 1.5rem; cursor: pointer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-6">
      <div class="form-group row justify-content-center">
        <label for="hasta" class="col-form-label">{{ 'BIOMEDIDAS.HISTORIAL.HASTA' | translate }}</label>
        <div class="datoCol fechaMedida input-group hasta">
          <input
            class="form-control"
            placeholder="yyyy-mm-dd"
            [(ngModel)]="fechaFin"
            (ngModelChange)="fechaChange()"
            ngbDatepicker
            #h="ngbDatepicker"
            id="hasta"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" (click)="h.toggle()" type="button">
              <i class="icon-calendario" style="font-size: 1.5rem; cursor: pointer"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <small class="col-12 error" *ngIf="errorFechas">
      {{ 'BIOMEDIDAS.HISTORIAL.ERRORES.FECHAS' | translate }}
    </small>
  </div>

  <div *ngIf="labels.length <= 0 && !loader.active" style="position: relative; height: 200px">
    <h4 class="texto-centrado">{{ 'BIOMEDIDAS.HISTORIAL.NOEXISTE' | translate }}</h4>
  </div>
  <div class="chartWrapper" *ngIf="!errorFechas">
    <div class="chartAreaWrapper2">
      <div id="myChart" echarts [options]="opciones" class="grafico"></div>
    </div>
  </div>
  <div *ngIf="labels.length > 0 && !errorFechas" class="tabla">
    <div class="row">
      <div class="labels"></div>
      <div *ngFor="let data of datasets; let o = index" class="col-2 cabeceras" [ngClass]="{ una: o == 0, dos: o == 1, tres: o == 2 }">
        {{ data.name | split: '_':1 }}
      </div>
    </div>
    <div class="row datos" *ngFor="let label of labels.slice().reverse(); let i = index">
      <div class="labels" *ngIf="translateService.currentLang !== 'en'">
        {{ label | date: 'dd-MM-yyyy\nHH:mm' }}
      </div>
      <div class="labels" *ngIf="translateService.currentLang === 'en'">
        {{ label | date: 'MM-dd-yyyy\nHH:mm' }}
      </div>
      <div class="col-2 datoTabla" *ngFor="let data of datasets; let u = index" [ngClass]="{ una: u == 0, dos: u == 1, tres: u == 2 }">
        <span *ngIf="!isNaN(data.data.slice().reverse()[i][1]) && data.formato.trim().toUpperCase() == 'DECIMAL'">
          {{ data.data.slice().reverse()[i][1] | number: '1.1-2' }}
        </span>
        <span *ngIf="!isNaN(data.data.slice().reverse()[i][1]) && data.formato.trim().toUpperCase() == 'ENTERO'">
          {{ data.data.slice().reverse()[i][1] | number: '1.0-0' }}
        </span>
        <span *ngIf="isNaN(data.data.slice().reverse()[i][1])">-</span>
      </div>
    </div>
  </div>
  <div style="height: 30px"></div>
</div>
