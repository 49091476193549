import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, CalendarioService, DispositivoService, PerfilService, LoaderService, GestionActBiomedidaService } from '../../../../core/services';
import { Actividad } from '../../../../core/models';

import { VistaCalendario } from '../vista-calendario.model';

/**
 * Componente para la vista diaria del calendario.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0300
 * @since 01.02.0000
 */
@Component({
  selector: 'app-dia',
  templateUrl: 'dia.component.html',
  styleUrls: ['../../calendario.component.css', './dia.component.css']
})
export class DiaComponent extends VistaCalendario {
  @Input() dia: Date;

  constructor(
    public loader: LoaderService,
    public alertService: AlertService,
    public calendarioService: CalendarioService,
    public perfilService: PerfilService,
    public dispositivoService: DispositivoService,
    public gestionActBiomedidaService: GestionActBiomedidaService,
    translateService: TranslateService

  ) {
    super(alertService, calendarioService, perfilService, dispositivoService, gestionActBiomedidaService, translateService);

    // Subscripción al evento reload.
    // Se recarga la vista diaria.
    this.subs.push(
      this.calendarioService.reload.subscribe(() => {
        const fecha = new Date();
        this.dia = fecha;
      })
    );
  }

  public actualizarActividades(actividades: Actividad[]): void {
    this.actividadesTratadas = actividades;
  }

  public sumar(): void {
    // Función que cambia la fecha a mostrar a la del día siguiente y carga los datos de ese día.
    const fecha = new Date(this.dia);
    fecha.setDate(fecha.getDate() + 1);
    this.dia = fecha;
    this.cargarActividades();
  }

  public restar(): void {
    // Función que cambia la fecha a mostrar a la del día anterior y carga los datos de ese día.
    const fecha = new Date(this.dia);
    fecha.setDate(fecha.getDate() - 1);
    this.dia = fecha;
    this.cargarActividades();
  }

  public calcularFechaInicial(): void {
    this.dia.setHours(0, 0, 0, 0);
    this.fechaIni = new Date(this.dia);
  }

  public calcularFechaFinal(): void {
    const fechaFin = new Date(this.dia);
    fechaFin.setDate(fechaFin.getDate() + 1);

    this.fechaFin = fechaFin;
  }
}
