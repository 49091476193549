import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, AutenticacionService, PerfilService, NotificacionesService, PermisosService, Operation } from '../../core/services';
import { InstruccionesComponent } from '../instrucciones/instrucciones.component';
import { Perfil, Permisos } from '../../core/models';

declare var $: any;

/**
 * Componente de la página principal.
 *
 * @author lreverendo
 *
 * @version 01.02.0000
 * @since 01.02.0000
 */
@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.css']
})
export class PrincipalComponent implements OnInit, OnDestroy {
  @ViewChild('instrucciones', { static: true }) instrucciones: InstruccionesComponent;

  public perfil: Perfil;
  public botonPanico: boolean;
  public tieneNasi = true;

  public cuidador: boolean;
  public operation = Operation;

  public numeroNotificacionesSinRevisar;

  private errorSubscription: Subscription;
  private perfilPacienteSubscription: Subscription;
  private numeroNotificacionesSubscription: Subscription;

  constructor(
    private alertService: AlertService,
    private autenticacionService: AutenticacionService,
    private perfilService: PerfilService,
    private notificacionesService: NotificacionesService,
    private translateService: TranslateService,
    public router: Router,
    private permisosService: PermisosService
  ) {}

  public ngOnInit(): void {
    const permisos: Permisos = {
      escritura: true,
      lectura: true
    };

    this.permisosService.setPermisos(permisos);

    this.errorSubscription = this.perfilService.error$.subscribe(this.gestionarError.bind(this));
    this.perfilPacienteSubscription = this.perfilService.perfilPaciente$.subscribe(this.actualizarPerfilPaciente.bind(this));

    this.numeroNotificacionesSubscription = this.notificacionesService.numeroNotificacionesNoRevisadas$.subscribe(
      this.actualizarNumeroNotificacionesSinRevisar.bind(this)
    );
  }

  public ngOnDestroy(): void {
    this.notificacionesService.detener();

    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }

    if (this.perfilPacienteSubscription) {
      this.perfilPacienteSubscription.unsubscribe();
    }

    if (this.numeroNotificacionesSubscription) {
      this.numeroNotificacionesSubscription.unsubscribe();
    }
  }

  public click(): void {
    $('#menu').collapse('hide');
  }

  public abrirMenu(): void {
    // Método para cambiar el estado (abierto-cerrado) del menú.
    if ($('#menu').hasClass('show')) {
      $('#menu').collapse('hide');
    } else {
      $('#menu').collapse('show');
    }
  }

  public abrir(hijo: string): void {
    // Función que redirige al hijo indicado.
    this.router.navigate(['/' + hijo]);
    $('#menu').collapse('hide');
    $('#filtros').collapse('show');
  }

  public abrirInstrucciones(): void {
    // Se llama al método de instrucciones, AbrirInstrucciones.
    $('#menu').collapse('hide');
    this.instrucciones.abrirInstrucciones();
  }

  public salir(): void {
    $('#menu').collapse('hide');
    // Se prepara el modal de salir.
    const data = {
      origen: this.autenticacionService,
      titulo: this.translateService.instant('MODALES.SALIR.TITULO'),
      mensaje: this.translateService.instant('MODALES.SALIR.MENSAJE'),
      buttons: [
        {
          texto: this.translateService.instant('MODALES.SALIR.NO'),
          type: 'button',
          cerrar: true
        },
        {
          texto: this.translateService.instant('MODALES.SALIR.SI'),
          type: 'button',
          accion: 'logout',
          success: true,
          cerrar: true
        }
      ],
      cerrar: false
    };

    // Se emite el evento modal.
    this.alertService.modal.emit(data);
  }

  public panico(): void {
    $('#menu').collapse('hide');
    // Se prepara el modal del botón del pánico.
    const data = {
      origen: this,
      titulo: this.translateService.instant('MODALES.AVISOPANICO.TITULO'),
      subtitulo: this.translateService.instant('MODALES.AVISOPANICO.SUBTITULO'),
      mensaje: this.translateService.instant('MODALES.AVISOPANICO.MENSAJE'),
      buttons: [
        {
          texto: this.translateService.instant('MODALES.AVISOPANICO.NO'),
          type: 'button',
          cerrar: true
        },
        {
          texto: this.translateService.instant('MODALES.AVISOPANICO.SI'),
          type: 'button',
          accion: 'lanzarBotonPanico',
          success: true,
          cerrar: true
        }
      ],
      cerrar: false
    };

    // Se emite el evento modal.
    this.alertService.modal.emit(data);
  }

  public lanzarBotonPanico(): void {
    // Función que envía la señal de pánico.
    this.perfilService.lanzarBotonPanico().subscribe(
      (_data) => {
        this.alertService.lanzarExito(this.translateService.instant('MODALES.AVISOPANICO.NOTIFICACION'));
      },
      (error) => {
        if (error.status !== 401 && error.status !== 403) {
          this.alertService.lanzarError(error.status);
        }
      }
    );
  }

  private actualizarPerfilPaciente(perfilPaciente: Perfil): void {
    if (perfilPaciente != null) {
      this.perfil = perfilPaciente;
      this.botonPanico = perfilPaciente.botonPanico;
      this.tieneNasi = this.perfilService.tieneNasi();
      this.cuidador = this.perfilService.esUsuarioCuidador();

      if (this.tieneNasi) {
        this.notificacionesService.iniciar();
      } else {
        this.abrir('/perfil');
      }
    }
  }

  private actualizarNumeroNotificacionesSinRevisar(numeroNotificacionesSinRevisar: Number): void {
    this.numeroNotificacionesSinRevisar = numeroNotificacionesSinRevisar;
  }

  private gestionarError(error: HttpErrorResponse): void {
    if (error.status !== 401 && error.status !== 403) {
      this.alertService.lanzarError(error.status);
    }
  }
}
