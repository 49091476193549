import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { CustomValidators } from 'src/app/shared/validators/custom-validators';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, CustomNgbDateParserFormatter } from 'src/app/shared/directives/custom-datepicker/custom-datepicker';
import { parse } from 'date-fns';
import { AlertService, AnaliticaService, CalendarioService, GestionActAnaliticaService } from 'src/app/core/services';
import { Actividad, ExtraccionAnalitica, Fecha } from 'src/app/core/models';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';

declare var $: any;

/**
 * @author lreverendo
 * @author dvila
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0030
 */

@Component({
  selector: 'app-extraccion-analitica',
  templateUrl: './extraccion-analitica.component.html',
  styleUrls: ['./extraccion-analitica.component.css'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }
  ]
})
export class ExtraccionAnaliticaComponent implements OnDestroy, OnInit {
  public actividad: Actividad;
  public fecha: Fecha;
  public hora: string;
  public forma: FormGroup;
  public mask: Array<string | RegExp> = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];

  private subs: Subscription[] = [];

  constructor(
    private translateService: TranslateService,
    private alertService: AlertService,
    private analiticaService: AnaliticaService,
    private gestionActAnliticaService: GestionActAnaliticaService,
    private calendarioService: CalendarioService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe
  ) {
    this.forma = this.formBuilder.group(
      {
        fecha: ['', Validators.required],
        hora: ['', [Validators.required, Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')]],
        observaciones: ['', Validators.pattern('^([ña-záéíóúA-ZÁÉÍÓÚ0-9 %/()¿?¡!ºª.,;:+\n]*)$')]
      },
      {
        validator: [CustomValidators.fechaInferior, CustomValidators.fechaSuperior]
      }
    );
  }

  public ngOnInit(): void {
    this.subs.push(
      this.gestionActAnliticaService.registroAnalitica.subscribe((actividad: Actividad) => {
        this.actividad = actividad;
        this.calcularFecha();
        this.construirFormulario();
        $('#analitica').modal('show');
      })
    );
  }

  private construirFormulario(): void {
    this.forma = this.formBuilder.group(
      {
        fecha: [this.fecha, Validators.required],
        hora: [this.hora, [Validators.required, Validators.pattern('^([01][0-9]|2[0-3]):[0-5][0-9]$')]],
        observaciones: ['', Validators.pattern('^([ña-záéíóúA-ZÁÉÍÓÚ0-9 %/()¿?¡!ºª.,;:+\n]*)$')]
      },
      {
        validator: [CustomValidators.fechaInferior, CustomValidators.fechaSuperior]
      }
    );
    this.forma.get('hora').valueChanges.subscribe(this.changeRegex.bind(this));
    this.forma.controls['hora'].markAsTouched();
  }

  private changeRegex(value: string): void {
    // Se comprueba el formato de la hora
    if (value.charAt(0) === '2') {
      this.mask[1] = new RegExp('[0-3]');
    } else {
      this.mask[1] = new RegExp('\\d');
    }
  }

  private calcularFecha(): void {
    // Se calcula la fecha actual.
    let fecha = new Date();
    this.hora = this.datePipe.transform(fecha, 'HH:mm');

    if (this.actividad && this.actividad.estado === 'AUSENCIA') {
      fecha = new Date(this.actividad.fechaInicioSinMargen);
      this.hora = '';
    }

    this.fecha = { year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: fecha.getDate() };
  }

  private getFechaToIsoString(fechaActividad: Fecha, hora: string): string {
    const { day, month, year } = fechaActividad;
    const fecha = parse(`${day}/${month}/${year} ${hora}`, 'dd/MM/yyyy HH:mm', new Date());
    return fecha.toISOString();
  }

  private gestionarExito() {
    const mensajeExito = this.translateService.instant('CALENDARIO.ANALITICA.CREAR.NOTIFICACION.SUCCESS');
    this.limpiar();
    $('#analitica').modal('hide');
    this.calendarioService.actualizarCalendario.emit();
    this.alertService.lanzarExito(mensajeExito);
  }

  private gestionarError(error: HttpErrorResponse) {
    this.limpiar();
    $('#analitica').modal('hide');
    this.alertService.lanzarError(error.status);
  }

  public limpiar(): void {
    this.calcularFecha();
    this.forma.reset({
      fecha: this.fecha,
      hora: this.hora,
      observaciones: ''
    });
  }

  public guardarCambios(): void {
    const { observaciones, fecha, hora } = this.forma.value;

    const analitica = new ExtraccionAnalitica();
    analitica.observaciones = observaciones.replace(/\n/g, '<br>');
    analitica.emisor = 'TELEA WEB';
    analitica.fechaActividad = this.getFechaToIsoString(fecha, hora);
    analitica.tipoExtraccion = '501';

    this.analiticaService.crearExtraccionAnalitica(analitica).subscribe(
      () => this.gestionarExito(),
      (error) => this.gestionarError(error)
    );
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
