import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { PerfilService } from './perfil.service';
import { ActividadesService } from './actividades.service';
import { Cuestionario, CuestionarioPlantilla, CuestionarioRespuesta } from '../models';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import { of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { startOfDay, addDays } from 'date-fns';




/**
 * Servicio para la gestión de los cuestionarios.
 *
 * @author lreverendo
 * @author dvila
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class CuestionariosService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService, private perfilService: PerfilService, private actividadesService: ActividadesService) {}

  public getHistorico(): Observable<Cuestionario[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarHistorico, dniPaciente);
    return this.http.get<Cuestionario[]>(url);
  }

  public getCuestionarioRealizado(id: string, cod: string): Observable<Cuestionario> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarCuestionarioRealizado, dniPaciente, id, cod);
    return this.http.get<Cuestionario>(url);
  }

  public getCuestionario(cod: string, locale: string = 'gl'): Observable<CuestionarioPlantilla> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarCuestionario, cod, dniPaciente, locale);
    return this.http.get<CuestionarioPlantilla>(url);
  }

  public enviarCuestionario(body: CuestionarioRespuesta): Observable<Cuestionario> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.enviarCuestionario, dniPaciente);
    return this.http.post<Cuestionario>(url, body);
  }

  public cuestionarioRealizadoINR(fecha: Date): Observable<boolean> {
    // Validar si el protocolo activo es distinto de 26
    if (this.perfilService.obtenerProtocoloActivo() !== 26) {
      return of(true);
    }
  
    const actividadesPrescritas = this.perfilService.obtenerActividadesPrescritas();
  
    // Validar si no hay actividades o cuestionarios prescritos
    if (actividadesPrescritas == null || actividadesPrescritas.cuestionarios == null) {
      return of(true);
    }
  
    // Validar si el cuestionario prescrito no incluye "ACT_CUES_43"
    if (!actividadesPrescritas.cuestionarios.some((cuestionario) => cuestionario === 'ACT_CUES_43')) {
      return of(true);
    }
  
    // Rango de fechas para la búsqueda
    const fechaInicialBusqueda = startOfDay(fecha);
    const fechaFinalBusqueda = startOfDay(addDays(fechaInicialBusqueda, 1));
  
    const fechaIni = fechaInicialBusqueda.toISOString();
    const fechaFin = fechaFinalBusqueda.toISOString();
  
    return this.actividadesService.getCuestionarios(fechaIni, fechaFin).pipe(
      switchMap((actividades) => {
        const tieneCuestionario = actividades.some((cuestionario) => cuestionario.agrupador === 'ACT_CUES_43');
        if (!tieneCuestionario) {
          return of(true);
        }
  
        // Obtener historial y verificar si el cuestionario está realizado
        return this.getHistorico().pipe(
          map((cuestionarios) => {
            const realizados = this.cuestionariosRealizadosPorFecha(fecha, cuestionarios);
            return realizados.some((cuestionario) => cuestionario.tipo === '43');
          })
        );
      }),
      catchError((error) => {
        console.error('Error al obtener cuestionarios:', error);
        return of(false); // O maneja el error según lo que necesites
      })
    );
  }
  
  

  private cuestionariosRealizadosPorFecha(fecha: Date, cuestionarios: Cuestionario[]): Cuestionario[] {
    return cuestionarios.filter((cuestionario) => {
      const date = new Date(cuestionario.fechaActividad);
      return date.getDate() === fecha.getDate() && date.getMonth() === fecha.getMonth() && date.getFullYear() === fecha.getFullYear();
    });
  }
}
