import { Injectable } from '@angular/core';
import { Actividad } from '../models';
import { AlertService } from './alert.service';
import { PermisosService } from './permisos.service';

import { isAfter } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { LocaleTextoPipe } from '../../shared/pipes/locale-texto/locale-texto';

/**
 * Servicio para la gestión del actividades de tipo videoconferencia.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0050
 */
@Injectable({ providedIn: 'root' })
export class GestionActVideoconferenciaService {
  constructor(
    private alertService: AlertService,
    private permisoService: PermisosService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {}

  public abrirVideoconferencia(actividad: Actividad): void {
    switch (actividad.estado) {
      case 'VACIO':
        if (isAfter(new Date(actividad.fechaInicioConMargen), new Date())) {
          this.videoconferenciaFuturo(actividad);
        } else if (this.permisoService.getPermisos().escritura) {
          this.realizarVideoconferencia(actividad);
        }
        break;
      case 'ADHERIDO':
        this.realizarVideoconferencia(actividad);
        break;
      case 'AUSENCIA':
        this.videoconferenciaCaducada(actividad);
        break;
    }
  }

  private realizarVideoconferencia(actividad: Actividad) {
    const url = actividad.url;
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;

    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const w = width - 50;
    const h = height - 50;

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    const newWindow = window.open(
      url,
      '_blank',
      'directories=0,menubar=0,location=0,status=0,titlebar=0,toolbar=0,' + 'width=' + w + ',height=' + h + ',top=' + top + ',left=' + left
    );

    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      this.alertService.showAlertToast('GENERAL.ERRORES.POPUP_BLOQUEADOS');
    } else {
      newWindow.focus();
    }
  }

  private videoconferenciaFuturo(actividad: Actividad): void {
    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('VIDEOCONFERENCIA.NOTIFICACION.FUTURO'),
      buttons: [],
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private videoconferenciaCaducada(actividad: Actividad): void {
    const data = {
      origen: this,
      titulo: this.localeTexto.transform(actividad.titulo, this.getCurrentIdioma()),
      mensaje: this.translateService.instant('VIDEOCONFERENCIA.NOTIFICACION.CADUCADO'),
      buttons: [],
      cerrar: true
    };

    this.alertService.modal.emit(data);
  }

  private getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }
}
