import { Pipe, PipeTransform } from '@angular/core';
import { getISOWeek } from 'date-fns';

/**
 * Pipe que devuelve el string pasado en mayúsculas.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Pipe({
  name: 'semana'
})
export class SemanaPipe implements PipeTransform {
  public transform(value: string): number {
    const fecha = new Date(parseInt(value, 10)); // Convierte el timestamp a una fecha
    return getISOWeek(fecha);
  }
}