<div class="container">
  <loader></loader>
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ 'DISPOSITIVOS.TITULO' | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>

  <div class="eventos-contenido" data-simplebar>
    
    <div *ngFor="let dispositivo of listaDispositivosTodos" class="list-group-item btn col-12 dispositivo">
      <div class="row">
        <img [src]="'assets/images/' + dispositivo['icono']" class="icono" />
        <div class="col">
          <div class="row">
            <div class="col dispositivoInfo">
              {{ 'DISPOSITIVOS.' + dispositivo['nombre'] | translate }}
            </div>
            <div class="botones-vinculacion-info">
              <div *ngIf="tieneVinculoDispositivo" >
                <div class="dispositivoVinculado">
                  {{ 'DISPOSITIVOS.VINCULACION.VINCULADO' | translate }}
                </div>
  
                <!----TODO PARA PROXIMA VERSION HABILITAR EL BOTON-->
                <!---
                <button class="btn btn-success-red boton-panico mt-2" type="button" (click)="confirmarDesvincular(dispositivo['nombre'])" disabled>
                  {{ 'DISPOSITIVOS.DESVINCULACION.DESVINCULAR' | translate }}
                </button>
                -->              
              </div>
              
              <button class="btn btn-primary" type="button" (click)="avisoParaVincular(dispositivo['nombre'])" *ngIf="!tieneVinculoDispositivo">
                {{ 'DISPOSITIVOS.VINCULACION.VINCULAR' | translate }}
              </button>
            </div>            
          </div>
          
        </div>
      </div>
    </div>


  </div>
</div>
