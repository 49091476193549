import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { AlertService, LoaderService, MensajeriaService } from '../../../../core/services';
import { AppConfigService } from '../../../../core/services';
import { Mensaje } from '../../../../core/models';

import { differenceInDays, parseISO, formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale'; // Importa los locales que necesites
/**
 * Componente para la gestión de los mensajes enviados.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Component({
  selector: 'app-mensajes-enviados',
  templateUrl: 'mensajes-enviados.component.html',
  styleUrls: ['./mensajes-enviados.component.css', '../../mensajeria.component.css']
})
export class MensajesEnviadosComponent implements OnInit, OnDestroy {
  @Input() permisoEnvioMensaje: Boolean;

  public mensajesEnviadosRecientes: Mensaje[] = [];
  public mensajesEnviadosAnteriores: Mensaje[] = [];

  private subs: Subscription[] = [];

  constructor(
    private alertService: AlertService,
    public loader: LoaderService,
    private mensajeriaService: MensajeriaService,
  ) {
    this.subs.push(this.mensajeriaService.reloadEnviados.subscribe(this.cargarMensajesEnviados.bind(this)));
  }

  public ngOnInit(): void {
    this.cargarMensajesEnviados();
  }

  public ngOnDestroy(): void {
    // Baja en todas las subscripciones.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public leerMensaje(mensaje: Mensaje): void {
    this.mensajeriaService.visualizarMensaje.emit({
      mensaje: mensaje,
      recibido: false
    });
  }

  public nuevoMensaje(): void {
    this.mensajeriaService.crearNuevoMensaje.emit();
  }

  private cargarMensajesEnviados(): void {
    this.mensajeriaService.getMensajesEnviados().subscribe(this.filtrarMensajesEnviados.bind(this), this.gestionarError.bind(this));
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }

  private filtrarMensajesEnviados(mensajesEnviados: Mensaje[]): void {
    this.mensajesEnviadosRecientes = mensajesEnviados.filter((mensaje) => this.comprobarReciente(mensaje));
    this.mensajesEnviadosAnteriores = mensajesEnviados.filter((mensaje) => this.comprobarAnterior(mensaje));
  }

  private comprobarReciente(mensaje: Mensaje): boolean {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(mensaje);

    const esMensajeReciente = diasDiferenciaFechaActual <= AppConfigService.diasMensajesRecientes;

    return esMensajeReciente;
  }

  private comprobarAnterior(mensaje: Mensaje): boolean {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(mensaje);

    const esMensajeAnterior = diasDiferenciaFechaActual > AppConfigService.diasMensajesRecientes;

    return esMensajeAnterior;
  }

  private getDiasDiferenciaFechaActual(mensaje: Mensaje): number {
    const fechaActual = new Date(); // Fecha actual
    const fechaNotificacion = new Date(mensaje.fechaEnvio); // Convertir a Date
    return differenceInDays(fechaActual, fechaNotificacion); // Calcular la diferencia en días
  }

  public getFechaMensajeFormateada(fecha: string): string {
    const fechaNotificacion = parseISO(fecha);
    return formatDistanceToNow(fechaNotificacion, { addSuffix: true, locale: es });
  }
}
