import { Injectable, Input, OnChanges, OnDestroy, OnInit, Directive } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AlertService, CalendarioService, PerfilService, DispositivoService, GestionActBiomedidaService, Operation } from '../../../core/services';

import { Actividad, Dispositivo } from '../../../core/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Clase genérica de las vistas del calendario.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0300
 * @since 01.02.0000
 */
@Directive()
@Injectable()
export abstract class VistaCalendario implements OnChanges, OnDestroy, OnInit {
  @Input() tiposActividad: String[];

  public actividadesTratadas: Actividad[] = [];

  public fechaIni: Date;
  public fechaFin: Date;

  public subs: Subscription[] = [];
  public operacionFinalizada = new Subject();
  public operacionPerfilFinalizada = new Subject();

  public dispositivosCargados: boolean;

  public operation = Operation;

  constructor(
    private alertServiceSuper: AlertService,
    private calendarioServiceSuper: CalendarioService,
    private perfilServiceSuper: PerfilService,
    private dispositivoServiceSuper: DispositivoService,
    public gestionActBiomedidaService: GestionActBiomedidaService,
    private translateServiceSuper: TranslateService
  ) {
    // Subscripción al evento actualizarCalendario.
    // Se recarga la vista.
    this.subs.push(this.calendarioServiceSuper.actualizarCalendario.subscribe(this.cargarActividades.bind(this)));
    this.dispositivosCargados = false;
  }

  public ngOnInit(): void {
    this.subs.push(
      this.dispositivoServiceSuper.dispositivos$.pipe(takeUntil(this.operacionFinalizada)).subscribe((dispositivos: Dispositivo[]) => {
        if (dispositivos == null || dispositivos.length === 0) {
          this.dispositivoServiceSuper.cargarDispositivos();
        } else {
          this.dispositivosCargados = true;
          this.operacionFinalizada.next();
        }
      })
    );

    
      // Subscripción al evento registroBiomedida.
      this.subs.push(
        this.gestionActBiomedidaService.registroBiomedidaCuestionarioINR.subscribe((data) => {
          const actividadSeleccionada = data.actividad;

          if (actividadSeleccionada.codigoActividad === "INR") {

            // encontrar la actividad en actividadesTratadas que tenga tipo "Cuestionarios_TELEA" y codigoActividad 43 y abrir actividad
            const cuestionarioINR = this.actividadesTratadas.find(cuesINR => cuesINR.tipo === 'Cuestionarios_TELEA' && cuesINR.codigoActividad === "43");
            if (cuestionarioINR) {
              this.calendarioServiceSuper.abrirActividad(cuestionarioINR);
            }

          }
  
        })
      )
  }

  public ngOnDestroy(): void {
    // Baja en todas las subscripciones.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  public ngOnChanges(): void {
    if (this.tiposActividad != null) {
      // Cargamos los datos cuando haya algún cambio en los atributos de entrada.
      this.cargarActividades();
    }
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateServiceSuper.currentLang;
  }

  public irDia(dia): void {
    // Se emite el evento vistaDia para mostrar la vista diaria en el día seleccionado.
    this.calendarioServiceSuper.vistaDia.emit({ dia: dia });
  }

  public crearActividad(): void {
    // Se emite el evento crearActividadPersonal que abre el modal donde crear una actividad personal.
    this.calendarioServiceSuper.crearActividadPersonal.emit();
  }

  public abrirActividad(actividad: Actividad): void {
    // Se llama al método encargado de abrir la actividad.
    this.calendarioServiceSuper.abrirActividad(actividad);
  }

  public cargarActividades(): void {
    this.subs.push(
      this.perfilServiceSuper.perfilPaciente$.pipe(takeUntil(this.operacionPerfilFinalizada)).subscribe((perfilPaciente) => {
        if (perfilPaciente != null) {
          this.calcularFechaInicial();
          this.calcularFechaFinal();
          if (this.tiposActividad.length === 0) {
            this.actualizarActividades([]);
          } else {
            this.calendarioServiceSuper
              .cargarActividades(this.fechaIni, this.fechaFin, this.tiposActividad, perfilPaciente.identificadorEjercicio)
              .subscribe(this.actualizarActividades.bind(this));
          }
        }
      }, this.gestionarError.bind(this))
    );
  }

  public abstract restar(): void;

  public abstract sumar(): void;

  public abstract calcularFechaInicial(): void;

  public abstract calcularFechaFinal(): void;

  public abstract actualizarActividades(actividades: Actividad[]): void;

  private gestionarError(error: HttpErrorResponse): void {
    this.alertServiceSuper.lanzarError(error.status);
  }
}
