import { Component, OnInit, ElementRef } from '@angular/core';
import { AutenticacionService } from './core/services/autenticacion.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ExternoService, PerfilService, NotificacionesService, SplashScreenService } from './core/services';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertService } from './core/services/alert.service';
import { VinculacionDispositivosService } from './core/services/vinculacionDispositivos.service';

/**
 * Componente raíz.
 *
 * @author lreverendo
 * @author dvila
 * @author aarodriguezo
 *
 * @version 01.02.0300
 * @since 01.02.0000
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  public template: string;
  public isUsuarioAutenticado = false;

  constructor(
    private autenticacionService: AutenticacionService,
    private alertService: AlertService,
    private externoService: ExternoService,
    private perfilService: PerfilService,
    private notificacionesService: NotificacionesService,
    private router: Router,
    private translateService: TranslateService,
    private splashScreenService: SplashScreenService,
    private vinculacionDispositivosService: VinculacionDispositivosService,
    private elementRef: ElementRef
  ) {
    this.template = '';
  }

  public ngOnInit(): void {
    this.autenticacionService.configurarInactividad();

    const native = this.elementRef.nativeElement;
    const operacion = native.getAttribute('operacion');

    // Si se cierra la sesión, se redirecciona a la página de login.
    this.autenticacionService.cierreSesionEvent.subscribe((errorSesion: { errorSesion: boolean }) => {
      this.isUsuarioAutenticado = false;
      this.router.navigate(['/login']);
      this.perfilService.detener();
      this.notificacionesService.detener();
      if (errorSesion.errorSesion) {
        this.alertService.lanzarErrorSesion();
      }
    });

    if (operacion != null) {
      let idioma = native.getAttribute('idioma');
      if (idioma != null) {
        idioma = idioma.toLowerCase();
      } else {
        idioma = 'gl';
      }
      const data = native.getAttribute('data');
      native.removeAttribute('operacion');
      native.removeAttribute('idioma');
      native.removeAttribute('data');
      this.externo(idioma, operacion, data);
    } else {
      const code = native.getAttribute('code');
      const state = native.getAttribute('state');
      let error = native.getAttribute('error');
      const system = native.getAttribute('system');
      let bind_result = native.getAttribute('bind_result');
      native.removeAttribute('code');
      native.removeAttribute('state');
      native.removeAttribute('error');
      native.removeAttribute('system');
      native.removeAttribute('bind_result');
      const idioma = this.getIdiomaLocalStorage();

      this.configurarIdioma(idioma);
      this.splashScreenService.cerrarSplashScreen();
      this.perfilService.error$.subscribe(this.gestionarError.bind(this));

      this.isUsuarioAutenticado = this.autenticacionService.isUsuarioAutenticado();
      const isPacienteSeleccionado = this.autenticacionService.isPacienteSeleccionado();
      if (this.isUsuarioAutenticado) {
        this.perfilService.cargarPerfilUsuario(this.autenticacionService.getLoginUsuario());
        if (isPacienteSeleccionado) {
          this.perfilService.cargarPerfilPaciente(this.autenticacionService.getLoginPaciente());
        }
        if (system === 'ABBOTT') {
          if (bind_result === "error"){
            error = "error";
            bind_result = null;
          }
          this.vinculacionDispositivosService.setSSOVinculacion(bind_result, error, system);
          this.router.navigate(['/dispositivos']);
        }
      } else {
        if (system === 'ESAUDE' && code != null && state != null) {
          this.router.navigate(['/login'], {
            queryParams: { code: code, state: state }
          });
        } else if (system === 'ESAUDE' && error != null) {
          this.router.navigate(['/login'], { queryParams: { error: error } });
        } else {
          this.router.navigate(['/login']);
        }
      }
    }
  }

  private externo(idioma: string, operacion: string, data: string) {
    this.configurarIdioma(idioma);
    this.externoService.setOperacion(operacion);
    this.externoService.setData(data);
    this.router.navigate(['/externo']);
  }

  private getIdiomaLocalStorage() {
    return localStorage.getItem('idioma') || 'gl';
  }

  private configurarIdioma(idioma: string): void {
    this.translateService.setDefaultLang(idioma);
    this.translateService.use(idioma);
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }
}
