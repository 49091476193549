import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertService, NotificacionesService } from '../../core/services';
import { Operation, AppConfig, AppConfigService } from '../../core/services';
import { Notificacion } from '../../core/models/notificacion.model';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { parseISO, formatDistanceToNow, differenceInDays } from 'date-fns';
import { es } from 'date-fns/locale';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from '../../core/services/loader.service';

/**
 * Componente que muestra una lista con las notificaciones del paciente.
 *
 * @author priveiro
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Component({
  selector: 'app-notificaciones',
  templateUrl: 'notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit, OnDestroy {
  private errorSubscription: Subscription;
  private notificacionesSubscription: Subscription;
  private nuevasNotificacionesSubscription: Subscription;

  public operation = Operation;
  public listaNotificacionesRecientes: Notificacion[] = [];
  public listaNotificacionesAnteriores: Notificacion[] = [];
  public operacionFinalizada = new Subject();

  constructor(
    public loader: LoaderService,
    private router: Router,
    private alertService: AlertService,
    private notificacionService: NotificacionesService,
    private translateService: TranslateService
  ) {
  }

  public ngOnInit(): void {
    this.errorSubscription = this.notificacionService.error$.subscribe(this.gestionarError.bind(this));
    this.notificacionService.notificaciones$.pipe(takeUntil(this.operacionFinalizada)).subscribe(this.cargarNotificaciones.bind(this));
    this.nuevasNotificacionesSubscription = this.notificacionService.nuevasNotificaciones$.subscribe(
      this.cargarNuevasNotificaciones.bind(this)
    );
  }

  private cargarNotificaciones(notificaciones: Notificacion[]): void {
    if (notificaciones != null) {
      this.cargarNotificacionesEnAgrupaciones(notificaciones);
      this.operacionFinalizada.next();
    }
  }

  private cargarNuevasNotificaciones(notificacionesNuevas: Notificacion[]): void {
    if (notificacionesNuevas != null) {
      for (const notificacion of notificacionesNuevas) {
        this.establecerDetallesNotificacion(notificacion);
        this.listaNotificacionesRecientes.unshift(notificacion);
      }
    }
  }

  private cargarNotificacionesEnAgrupaciones(listaNotificaciones: Notificacion[]): void {
    for (const notificacion of listaNotificaciones) {
      this.establecerDetallesNotificacion(notificacion);
      this.cargarNotificacionEnAgrupacionTemporal(notificacion);
    }
  }

  private establecerDetallesNotificacion(notificacion: Notificacion): void {
    let i18nTitulo: string;
    let i18nDescripcion: string;
    let icono: string;

    switch (notificacion.tipo) {
      case 'ActividadCalendario':
        i18nTitulo = 'NOTIFICACIONES.ACTIVIDAD_TITULO';
        i18nDescripcion = 'NOTIFICACIONES.ACTIVIDAD_DESCRIPCION';
        icono = 'actividad_programada';
        break;
      case 'Mensajeria_TELEA':
        i18nTitulo = 'NOTIFICACIONES.MENSAJERIA_TITULO';
        i18nDescripcion = 'NOTIFICACIONES.MENSAJERIA_DESCRIPCION';
        icono = 'mensaje';
        break;
      case 'VideoConferencia_TELEA':
        i18nTitulo = 'NOTIFICACIONES.VIDEOCONFERENCIA_TITULO';
        i18nDescripcion = 'NOTIFICACIONES.VIDEOCONFERENCIA_DESCRIPCION';
        icono = 'videoconferencia';
        break;
      default:
        i18nTitulo = 'NOTIFICACIONES.ACTIVIDAD_TITULO';
        i18nDescripcion = 'NOTIFICACIONES.ACTIVIDAD_DESCRIPCION';
        icono = 'actividad_programada';
        break;
    }

    notificacion.titulo = this.translateService.instant(i18nTitulo);
    notificacion.descripcion = this.translateService.instant(i18nDescripcion);
    notificacion.icono = icono;
    notificacion.fechaFormateada = this.getFechaNotificacionFormateada(notificacion);
  }

  private getFechaNotificacionFormateada(notificacion: Notificacion): string {
    const fechaNotificacion = parseISO(notificacion.fecha);
    return formatDistanceToNow(fechaNotificacion, { addSuffix: true, locale: es });
  }

  private cargarNotificacionEnAgrupacionTemporal(notificacion: Notificacion): void {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(notificacion);

    if (diasDiferenciaFechaActual <= AppConfigService.diasNotificacionesRecientes) {
      this.listaNotificacionesRecientes.push(notificacion);
    } else {
      this.listaNotificacionesAnteriores.push(notificacion);
    }
  }

  private getDiasDiferenciaFechaActual(notificacion: Notificacion): number {
    const fechaActual = new Date();
    const fechaNotificacion = parseISO(notificacion.fecha);
    return differenceInDays(fechaActual, fechaNotificacion);
  }

  public revisarNotificacion(notificacion: Notificacion): void {
    this.notificacionService.marcarNotificacionRevisada(notificacion).subscribe(
      (marcadaComoRevisada: boolean) => {
        if (marcadaComoRevisada) {
          this.eliminarNotificacionSiEsAnterior(notificacion);
        }
      },
      (error) => {
        this.gestionarError(error);
      }
    );
    this.ejecutarAccionNotificacion(notificacion);
  }

  private eliminarNotificacionSiEsAnterior(notificacion: Notificacion): void {
    const diasDiferenciaFechaActual = this.getDiasDiferenciaFechaActual(notificacion);
    if (diasDiferenciaFechaActual > AppConfigService.diasNotificacionesRecientes) {
      const posicionEnArray = this.listaNotificacionesAnteriores.indexOf(notificacion);
      if (posicionEnArray !== -1) {
        this.listaNotificacionesAnteriores.splice(posicionEnArray, 1);
      }
    }
  }

  private ejecutarAccionNotificacion(notificacion: Notificacion): void {
    switch (notificacion.tipo) {
      case 'ActividadCalendario':
        this.mostrarCalendario();
        break;
      case 'Mensajeria_TELEA':
        this.mostrarMensajes(notificacion);
        break;
      case 'VideoConferencia_TELEA':
        this.iniciarVideoconferencia(notificacion.contenido);
        break;
      default:
        break;
    }
  }

  private mostrarCalendario(): void {
    this.router.navigate(AppConfig.route.CALENDARIO);
  }

  private mostrarMensajes(notificacion: Notificacion): void {
    const fechaNotificacionMensaje = { fechaNot: notificacion.fecha };
    this.router.navigate(AppConfig.route.MENSAJES, { queryParams: fechaNotificacionMensaje });
  }

  private iniciarVideoconferencia(url: string): void {
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;

    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const w = width - 50;
    const h = height - 50;

    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;

    const newWindow = window.open(
      url,
      '_blank',
      'directories=0,menubar=0,location=0,status=0,titlebar=0,toolbar=0,' + 'width=' + w + ',height=' + h + ',top=' + top + ',left=' + left
    );

    if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
      this.alertService.showAlertToast('GENERAL.ERRORES.POPUP_BLOQUEADOS');
    } else {
      newWindow.focus();
    }
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }

    if (this.notificacionesSubscription) {
      this.notificacionesSubscription.unsubscribe();
    }

    if (this.nuevasNotificacionesSubscription) {
      this.nuevasNotificacionesSubscription.unsubscribe();
    }
  }
}
