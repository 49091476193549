<div class="container hijo" data-simplebar>
  <div class="row tel-encabezado-pagina">
    <div class="col-12">
      <h3 class="tel-titulo-pagina">
        <strong>{{ 'MENSAJERIA.ENVIADOS.TITULO' | translate }}</strong>
      </h3>
    </div>
  </div>
  <div class="row">
    <div class="separador"></div>
  </div>

  <div class="row justify-content-center">
    <i class="icon-info"></i>
    <div class="mensaje">
      <div>{{ 'MENSAJERIA.AVISO' | translate }}</div>
      <div>{{ 'MENSAJERIA.AVISO2' | translate }}</div>
    </div>
  </div>

  <div class="row linkNuevoMensaje" *ngIf="permisoEnvioMensaje">
    <div class="col-12">
      <i class="icon-mas masNuevoMensaje"></i>
      <div class="nuevoMensaje" (click)="nuevoMensaje()">
        {{ 'MENSAJERIA.NUEVO' | translate }}
      </div>
    </div>
  </div>

  <div class="tel-cuerpo-pagina col-12" data-simplebar>
    <loader></loader>

    <div
      class="row justify-content-center"
      *ngIf="mensajesEnviadosRecientes.length == 0 && mensajesEnviadosAnteriores.length == 0 && !loader.active"
    >
      <div class="sinDatos col-12">
        <h3>{{ 'MENSAJERIA.ENVIADOS.SIN_DATOS' | translate }}</h3>
      </div>
    </div>

    <!-- ----------------------------------------------------------------------- -->
    <!--                     MENSAJES EN LOS ULTIMOS 7 DIAS                      -->
    <!-- ----------------------------------------------------------------------- -->
    <div
      class="row justify-content-center mb-4"
      *ngIf="(mensajesEnviadosRecientes.length > 0 || mensajesEnviadosAnteriores.length > 0) && !loader.active"
    >
      <div class="tel-cabecera-grupo">
        <div class="d-flex w-100">
          <h5 class="mb-1">{{ 'MENSAJERIA.RECIENTES' | translate }}</h5>
        </div>
      </div>
      <ng-container *ngIf="mensajesEnviadosRecientes.length > 0; else sinMensajesEnviadosRecientes">
        <ul class="list-group w-100">
          <li
            *ngFor="let mensaje of mensajesEnviadosRecientes"
            class="tel-mensaje list-group-item list-group-item-action flex-column align-items-start"
            [ngClass]="{ leido: mensaje.leido }"
            (click)="leerMensaje(mensaje)"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex w-100 h-100 justify-content-between align-items-center">
                  <div>{{ mensaje.asunto | htmlTexto }}</div>
                  <div class="tel-tiempo-mensaje">{{ getFechaMensajeFormateada(mensaje.fechaEnvio) }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-template #sinMensajesEnviadosRecientes>
        <p class="sin-mensajes">{{ 'MENSAJERIA.VACIOULTIMOSDIAS' | translate }}</p>
      </ng-template>
    </div>

    <!-- ----------------------------------------------------------------------- -->
    <!--                           MENSAJES ANTERIORES                           -->
    <!-- ----------------------------------------------------------------------- -->
    <div
      class="row justify-content-center"
      *ngIf="(mensajesEnviadosRecientes.length > 0 || mensajesEnviadosAnteriores.length > 0) && !loader.active"
    >
      <div class="tel-cabecera-grupo">
        <div class="d-flex w-100">
          <h5 class="mb-1">{{ 'MENSAJERIA.ANTERIORES' | translate }}</h5>
        </div>
      </div>
      <ng-container *ngIf="mensajesEnviadosAnteriores.length > 0; else sinMensajesEnviadosAnteriores">
        <ul class="list-group w-100">
          <li
            *ngFor="let mensaje of mensajesEnviadosAnteriores"
            class="tel-mensaje list-group-item list-group-item-action flex-column align-items-start"
            [ngClass]="{ leido: mensaje.leido }"
            (click)="leerMensaje(mensaje)"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex w-100 h-100 justify-content-between align-items-center">
                  <div>{{ mensaje.asunto | htmlTexto }}</div>
                  <div class="tel-tiempo-mensaje">{{ getFechaMensajeFormateada(mensaje.fechaEnvio) }}</div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </ng-container>
      <ng-template #sinMensajesEnviadosAnteriores>
        <p class="sin-mensajes">{{ 'MENSAJERIA.VACIOANTERIORES' | translate }}</p>
      </ng-template>
    </div>
  </div>
</div>
