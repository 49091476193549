import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Dispositivo } from '../../../core/models';

/**
 *  Directiva que permite identificar el icono de cada dispositivo
 *
 * @author aarodriguezo
 *
 * @version 01.02.0200
 * @since 01.02.0200
 */

@Directive({
  selector: '[appTipoDispositivoBiomedida]'
})
export class TipoDispositivoBiomedidaDirective implements OnInit {
  @Input() dispositivoActual: Dispositivo;

  private listaDispositivosIconos = [
    { dispositivo: 'IMC', icono: 'icon-bascula_imc' },
    { dispositivo: 'BASC', icono: 'icon-bascula' },
    { dispositivo: 'COAG', icono: 'icon-coagulometro' },
    { dispositivo: 'GLUC', icono: 'icon-glucometro' },
    { dispositivo: 'PULS', icono: 'icon-pulsiosimetro' },
    { dispositivo: 'TENS', icono: 'icon-tensiometro' },
    { dispositivo: 'TERM', icono: 'icon-termometro' },
    { dispositivo: 'INS', icono: 'icon-insulina' },
    { dispositivo: 'RHC', icono: 'icon-hidratos' },
    { dispositivo: 'EF', icono: 'icon-ejercicio' }
  ];

  constructor(private renderer: Renderer2, private elemRef: ElementRef) {}

  ngOnInit(): void {
    let clase = '';
    const tipoDispositivo: string = this.dispositivoActual.subtipo.cod;

    this.listaDispositivosIconos.forEach((dispositivoLista) => {
      if (tipoDispositivo.includes(dispositivoLista.dispositivo)) {
        clase = dispositivoLista.icono;
      }
    });
    this.renderer.addClass(this.elemRef.nativeElement, clase);
  }
}
