import { Component, HostListener, ViewChild, OnInit, OnDestroy, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { UpperCasePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { LoginService, NavegacionService, PerfilService, AppConfigService } from '../../core/services';
import { RecuperacionComponent } from './modals/recuperacion/recuperacion.component';
import { Subscription, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RespuestaLoginEsaude, Perfil } from '../../core/models';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

/**
 * Componente para la vista de login.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0070
 * @since 01.02.0000
 */
@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css', '../../shared/components/loader/loader.component.css']
})
export class LoginComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('recuperacion', { static: true }) recuperacion: RecuperacionComponent;

  public forma: FormGroup;
  public pin = false;
  public idioma: string;
  public errorServidor = false;
  public errorEsaudeSeguridad = false;
  public errorEsaudeAcceso = false;
  public version: string;
  public operacionFinalizada = new Subject();
  public redireccionExterna = false;
  public heightAuto: Boolean = false;
  private altoCalculado = false;
  private focusPin = false;
  private usuario: string;
  private errorSubscription: Subscription;
  private paramsSubscription: Subscription;

  constructor(
    private loginService: LoginService,
    private navegacionService: NavegacionService,
    private perfilService: PerfilService,
    private translateService: TranslateService,
    private uppercase: UpperCasePipe,
    private route: ActivatedRoute
  ) {
    this.version = AppConfigService.version;
    this.errorServidor = false;
    this.errorSubscription = this.perfilService.error$.subscribe(this.mostrarErrorServidor.bind(this));
    this.crearFormularioLogin();
  }

  public ngOnInit(): void {
    this.establecerIdioma();
    this.redireccionExterna = false;
    this.paramsSubscription = this.route.queryParams.subscribe((params) => {
      const code = params['code'];
      const state = params['state'];
      const error = params['error'];

      if (error != null) {
        this.errorLogin(error);
      } else if (code != null && state != null) {
        this.loginEsaude({ code, state });
      } else {
        $('input:text').focus();
      }
    });
  }

  private crearFormularioLogin() {
    this.forma = new FormGroup({
      usuario: new FormControl('', Validators.required),
      contrasena: new FormControl('', Validators.required)
    });
  }

  private errorLogin(error: string) {
    if (error === '401') {
      this.limpiarFormulario();
      this.errorEsaudeAcceso = true;
    } else if (error === '403') {
      this.limpiarFormulario();
      this.errorEsaudeSeguridad = true;
    } else {
      this.mostrarErrorServidor();
    }
  }

  public ngAfterViewChecked(): void {
    if (this.focusPin) {
      $('input:password').focus();
      this.focusPin = false;
    }
    if (!this.altoCalculado) {
      setTimeout(() => this.calcularAlto());
    }
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }
    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  private establecerIdioma() {
    this.idioma = this.obtenerIdiomaLocalStorage();
    this.translateService.setDefaultLang(this.idioma);
    this.translateService.use(this.idioma);
  }

  private obtenerIdiomaLocalStorage() {
    if (localStorage.getItem('locale')) {
      return localStorage.getItem('locale');
    } else {
      localStorage.setItem('locale', 'gl');
      return 'gl';
    }
  }

  public cambiarIdioma(idioma: string): void {
    this.idioma = idioma;
    localStorage.setItem('locale', idioma);
    this.translateService.use(idioma);
  }

  @HostListener('window:resize', ['$event'])
  public onResize(_event): void {
    this.calcularAlto();
  }

  private calcularAlto(): void {
    this.altoCalculado = true;
    const container = $(window).height() - 72;
    const content = $('.login-content').children().first().height();
    const footer = $('.login-footer').height();
    this.heightAuto = !(container > content + footer);
  }

  @HostListener('document:keypress', ['$event'])
  public handleKeyboardEvent(event: KeyboardEvent): void {
    const target: any = event.target;
    const classList: DOMTokenList = target.classList;
    if (classList.contains('recuperar')) {
      this.recuperarContrasena();
    }
  }

  public enviar(): void {
    this.errorServidor = false;

    this.usuario = this.uppercase.transform(this.forma.value.usuario);
    this.usuario = this.usuario.replace(/\s/g, ''.toUpperCase());

    const peticion = {
      usuario: this.usuario,
      contrasena: this.forma.value.contrasena
    };

    if (!this.pin) {
      this.login(peticion);
    } else {
      this.confirmarPin(peticion);
    }
  }

  public recuperarContrasena(): void {
    this.recuperacion.abrirModal(this.forma.value.usuario);
  }

  public abrirManual(): void {
    if (this.translateService.currentLang === 'en') {
      window.open(AppConfigService.urlManualEn, '_blank');
    } else {
      window.open(AppConfigService.urlManual, '_blank');
    }
  }

  public irEsaude(): void {
    this.loginService.recuperarUrlEsaude().subscribe(
      (urlEsaude) => {
        this.mostrarLoader();
        window.location.href = urlEsaude['url'];
      },
      (_error) => {
        this.mostrarErrorServidor();
      }
    );
  }

  protected login(peticion: any): void {
    // Se llama al método encargado de comprobar usuario y contraseña.
    this.loginService.prepararLogin(peticion).subscribe(this.loginExito.bind(this), (error) => {
      if (error.status === 401) {
        $(':submit').focus();
        this.forma.controls['contrasena'].setErrors({ credenciales: true });
      } else {
        this.mostrarErrorServidor();
      }
    });
  }

  protected confirmarPin(peticion: any): void {
    // Se llama al método encargado de comprobar usuario y pin.
    this.loginService.enviarPin(peticion).subscribe(
      (exito: Boolean) => {
        if (exito) {
          this.navegacionService.cargarPerfilUsuario(this.usuario);
        }
      },
      (error) => {
        if (error.status === 401) {
          $(':submit').focus();
          this.forma.controls['contrasena'].setErrors({ pinincorrecto: true });
        } else {
          this.mostrarErrorServidor();
        }
      }
    );
  }

  protected loginEsaude(peticion: any): void {
    // Se llama al método encargado de comprobar usuario y pin.
    this.loginService.loginEsaude(peticion).subscribe(this.loginEsaudeExito.bind(this), (error) => {
      this.logoutEsaude(error.status);
    });
  }

  private loginEsaudeExito(respuesta: RespuestaLoginEsaude): void {
    this.perfilService.perfilUsuario$.pipe(takeUntil(this.operacionFinalizada)).subscribe((perfil: Perfil) => {
      if (perfil == null) {
        this.perfilService.cargarPerfilUsuario(respuesta.dni);
      } else {
        this.logoutEsaude();
      }
    });
  }

  private logoutEsaude(errorStatus?: string): void {
    this.operacionFinalizada.next();
    let params = '';
    if (errorStatus) {
      params = 'sso?error=' + errorStatus;
    }
    this.loginService.recuperarUrlLogoutEsaude(params).subscribe(
      (urlLogoutEsaude) => {
        this.mostrarLoader();
        window.location.href = urlLogoutEsaude['url'];
      },
      (_error) => {
        this.mostrarErrorServidor();
      }
    );
  }

  private loginExito(): void {
    this.forma.reset({
      usuario: this.usuario,
      contrasena: ''
    });
    // Se pide el pin.
    this.pin = true;
    this.focusPin = true;
  }

  private mostrarErrorServidor(): void {
    this.limpiarFormulario();
    this.errorServidor = true;
  }

  private limpiarFormulario(): void {
    this.forma.reset({
      usuario: '',
      contrasena: ''
    });
    // Se pide el pin.
    this.pin = false;
    this.errorEsaudeAcceso = false;
    this.errorEsaudeSeguridad = false;
    this.errorServidor = false;
  }

  private mostrarLoader(): void {
    const loaderOverlay = document.createElement('div');
    loaderOverlay.id = 'loaderOverlay';
    loaderOverlay.className = 'loader-overlay';
    document.body.appendChild(loaderOverlay);
    this.redireccionExterna = true;
  }
}
