import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { Actividad } from '../../../core/models/actividad.model';
import { CalendarioService } from '../../../core/services/calendario.service';
import { DispositivoService } from '../../../core/services/dispositivo.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Dispositivo } from '../../../core/models';

@Directive({
  selector: '[appClaseTipoActividad]'
})
export class ClaseTipoActividadDirective implements OnInit {
  @Input() appClaseTipoActividad: Actividad;
  @Input() conDispositivo: boolean;

  public operacionFinalizada = new Subject();

  constructor(
    private dispositivoService: DispositivoService,
    private calendarioService: CalendarioService,
    private renderer: Renderer2,
    private elemRef: ElementRef
  ) {}

  ngOnInit(): void {
    let clase = '';
    const estado: string = this.appClaseTipoActividad.estado;

    switch (estado) {
      case 'ADHERIDO': {
        clase = 'actRealizada';
        break;
      }
      case 'AUSENCIA': {
        clase = 'actCaducada';
        break;
      }
      case 'VACIO': {
        if (this.appClaseTipoActividad.todoElDia) {
          clase = 'actTodoDia';
        } else {
          clase = 'actPautada';
        }
        break;
      }
    }

    this.renderer.addClass(this.elemRef.nativeElement, clase);

    const tipo: string = this.appClaseTipoActividad.tipo;

    switch (tipo) {
      case this.calendarioService.tiposActividad.personal: {
        clase = 'icon-actividadPersonal';
        break;
      }
      case this.calendarioService.tiposActividad.biomedida: {
        if (this.conDispositivo) {
          clase = this.buscarDispositivo(this.appClaseTipoActividad);
        } else {
          clase = 'icon-biomedidas_listado';
        }
        break;
      }
      case this.calendarioService.tiposActividad.cuestionario: {
        clase = 'icon-cuestionario_listado';
        break;
      }
      case this.calendarioService.tiposActividad.videoconferencia: {
        clase = 'icon-videoconferencia_2';
        break;
      }
      case this.calendarioService.tiposActividad.analitica: {
        clase = 'icon-analitica2';
        break;
      }
    }

    this.renderer.addClass(this.elemRef.nativeElement, clase);
  }

  private buscarDispositivo(actividad: Actividad): string {
    let clase = 'icon-biomedidas_listado';
    let dispositivo;

    this.dispositivoService.dispositivos$.pipe(takeUntil(this.operacionFinalizada)).subscribe((dispositivos: Dispositivo[]) => {
      if (dispositivos.length !== 0) {
        this.operacionFinalizada.next();
        dispositivo = this.dispositivoService.buscarDispositivo(actividad, dispositivos);
        if (dispositivo) {
          const codSubtipo = dispositivo.subtipo.cod;

          switch (codSubtipo) {
            case 'BASC': {
              clase = 'icon-biomedidas_bascula';
              break;
            }
            case 'IMC': {
              clase = 'icon-biomedidas_basculaIMC';
              break;
            }
            case 'TENS': {
              clase = 'icon-biomedidas_tensiometro';
              break;
            }
            case 'COAG': {
              clase = 'icon-biomedidas_coagulometro';
              break;
            }
            case 'GLUC': {
              clase = 'icon-biomedidas_glucometro';
              break;
            }
            case 'RHC': {
              clase = 'icon-biomedidas_hidratos';
              break;
            }
            case 'EF': {
              clase = 'icon-biomedidas_ejercicio';
              break;
            }
            case 'INS': {
              clase = 'icon-biomedidas_insulina';
              break;
            }
            case 'TERM': {
              clase = 'icon-biomedidas_termometro';
              break;
            }
            case 'PULS': {
              clase = 'icon-biomedidas_pulso';
              break;
            }
          }
        }
      }
    });

    return clase;
  }
}
