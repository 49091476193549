import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ActividadesService, AlertService, AppConfig, CalendarioService, LoaderService } from '../../../../core/services';
import { Actividad } from '../../../../core/models/actividad.model';
import { format, isBefore, parseISO } from 'date-fns';

/**
 * Componente con la lista de actividades cuestionario del día actual.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Component({
  selector: 'app-listado-cuestionario',
  templateUrl: './listado-cuestionario.component.html',
  styleUrls: ['../../cuestionarios.component.css', './listado-cuestionario.component.css']
})
export class ListadoCuestionarioComponent implements OnInit, OnDestroy {
  public actividadesTratadas: Actividad[] = [];

  private fechaIni: string;
  private fechaFin: string;

  private actividades: Actividad[] = [];

  private sub: Subscription;

  constructor(
    public loader: LoaderService,
    private actividadesService: ActividadesService,
    public calendarioService: CalendarioService,
    private alertService: AlertService,
    private translateService: TranslateService
  ) {
    // Subscripción en el evento actualizarCalendario.
    this.sub = this.calendarioService.actualizarCalendario.subscribe((_data) => {
      this.ngOnInit();
    });
  }

  public ngOnInit(): void {
 // Función para la carga de las actividades.
 const fechaInicialBusqueda = new Date();
 fechaInicialBusqueda.setHours(23, 0, 0, 0); // Ajuste para las 23:00 del día anterior
 fechaInicialBusqueda.setDate(fechaInicialBusqueda.getDate() - 1); // Ajuste para el día anterior

 const fechaFinalBusqueda = new Date(fechaInicialBusqueda);
 fechaFinalBusqueda.setDate(fechaFinalBusqueda.getDate() + 1); // Ajuste para el día siguiente

    // Convertir a formato ISO usando el formato de AppConfig
    this.fechaIni = format(fechaInicialBusqueda, AppConfig.constant.utcFormat);
    this.fechaFin = format(fechaFinalBusqueda, AppConfig.constant.utcFormat);
  
    this.actividadesService
      .getCuestionarios(this.fechaIni, this.fechaFin)
      .subscribe(this.actualizarActividades.bind(this), this.gestionarError.bind(this));
}

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }

  private actualizarActividades(actividades: Actividad[]): void {
    this.actividades = actividades;
    this.filtrarActividades();
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }

  private filtrarActividades(): void {
    const actividadesCalen: Actividad[] = [];
    const actividadesTodoElDia: Actividad[] = [];
    let actividades: Actividad[] = [];
    for (const actividad of this.actividades) {
      const fechaInicio = parseISO(actividad.fechaInicioSinMargen);
      const fechaFinal = parseISO(actividad.fechaFinalSinMargen);
  
      // Se comprueba si la actividad es de todo el día o pautada.
      if (
        format(fechaInicio, 'HH:mm') === '00:00' &&
        format(fechaFinal, 'HH:mm') === '00:00' &&
        isBefore(fechaInicio, fechaFinal)
      ) {
        actividad.todoElDia = true;
        actividadesTodoElDia.push(actividad);
      } else {
        actividad.todoElDia = false;
        actividadesCalen.push(actividad);
      }
    }
    actividades = actividades.concat(actividadesTodoElDia, actividadesCalen);
  
    this.actividadesTratadas = actividades;
  }
}
