import { Directive, ElementRef, Input, Output, OnInit, EventEmitter, HostListener, Renderer2 } from '@angular/core';
import { Actividad } from '../../../core/models/actividad.model';
import { PermisosService, CalendarioService } from '../../../core/services';

@Directive({
  selector: '[appBotonActividadPermiso]'
})
export class BotonActividadPermisoDirective implements OnInit {
  @Input() appBotonActividadPermiso: Actividad;
  @Output() clickBotonActividadPermiso: EventEmitter<any> = new EventEmitter();

  private click: boolean;

  constructor(
    private renderer: Renderer2,
    private elemRef: ElementRef,
    private permisosService: PermisosService,
    private calendarioService: CalendarioService
  ) {}

  ngOnInit(): void {
    this.click = true;
    const estado: string = this.appBotonActividadPermiso.estado;
    const tipo: string = this.appBotonActividadPermiso.tipo;

    if (
      !estado.includes('ADHERIDO') &&
      !tipo.includes(this.calendarioService.tiposActividad.personal) &&
      !this.permisosService.getPermisos().escritura
    ) {
      this.renderer.addClass(this.elemRef.nativeElement, 'disabledBtn');
      this.click = false;
    }
  }

  @HostListener('click', ['$event'])
  public onClick(_event: EventEmitter<any>): void {
    if (this.click) {
      this.clickBotonActividadPermiso.emit();
    }
  }
}
