import { Injectable, Input, OnChanges, Directive } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VariableClinica } from '../../../../core/models';

/**
 *  Clase padre de los dispositivos.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0300
 * @since 01.02.0000
 */
@Directive()
@Injectable({ providedIn: 'root' })
export abstract class DispositivoGenerico implements OnChanges {
  public static PATRON_DECIMAL = '^[0-9]{0,4}((.|,)([0-9]{1,2}))?$';
  public static PATRON_ENTERO = '^[0-9]{0,5}$';

  @Input() variablesClinicas: VariableClinica[];
  @Input() prandial: VariableClinica;
  @Input() forma: FormGroup;
  @Input() botonActivadoPorCuestionario: boolean;
  @Input() botonActivadoPorVisitaINR: boolean;


  constructor() {}

  public ngOnChanges(): void {
    this.changeMaxMin();
    if (this.prandial == null) {
      this.prandial = this.variablesClinicas[0];
    }
  }

  public limpiar(): void {
    // Método para resetear el formulario.
    Object.keys(this.forma.controls).map((k) => this.forma.controls[k].reset(''));
  }

  public abstract getData(): VariableClinica[];

  public changeMaxMin(): void {
    // Método opcional
  }

  /*
   * Método que devuelve el patrón a utilizar según el formato (entero o decimal) según el formato que tenga configurado la variable clínica
   */
  public getPatronFormato(formato: string = ''): string {
    switch (formato.trim().toUpperCase()) {
      case 'DECIMAL':
        return DispositivoGenerico.PATRON_DECIMAL;

      case 'ENTERO':
        return DispositivoGenerico.PATRON_ENTERO;

      default:
        return DispositivoGenerico.PATRON_ENTERO;
    }
  }
}
