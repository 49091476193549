import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Perfil, RespuestaPeticion, SSO, ModalButton} from '../../core/models';
import { AlertService, PerfilService, VinculacionDispositivosService, LoaderService, AppConfigService } from '../../core/services';
import { TranslateService } from '@ngx-translate/core';


/**
 * Componente que muestra la información de soporte.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0310
 * @since 01.02.0100
 */
@Component({
  selector: 'app-dispositivos',
  templateUrl: 'dispositivos.component.html',
  styleUrls: ['./dispositivos.component.css']
})
export class DispositivosComponent implements OnInit, OnDestroy {
  public tieneVinculoDispositivo = true;
  public listaDispositivosTodos = Object.values(AppConfigService.listaVinculacionDispositivos);

  private errorSubscription: Subscription;
  private perfilPacienteSubscription: Subscription;
  private paramsSubscription: Subscription;

  private ssoVinculacion: SSO;
  private perfilPaciente: Perfil;

  

  constructor(
    private alertService: AlertService,
    public loader: LoaderService,
    private perfilService: PerfilService,
    private translateService: TranslateService,
    private vinculacionDispositivosService: VinculacionDispositivosService  ) {}

  public ngOnInit(): void {
    this.errorSubscription = this.perfilService.error$.subscribe(this.gestionarError.bind(this));
    this.perfilPacienteSubscription = this.perfilService.perfilPaciente$.subscribe(this.actualizarPerfilPaciente.bind(this));
    this.ssoVinculacion = this.vinculacionDispositivosService.getSSOVinculacion();

    if (this.ssoVinculacion != null) {
      this.vincularDispositivo();
    }
  }

  public ngOnDestroy(): void {
    if (this.errorSubscription) {
      this.errorSubscription.unsubscribe();
    }

    if (this.perfilPacienteSubscription) {
      this.perfilPacienteSubscription.unsubscribe();
    }

    if (this.paramsSubscription) {
      this.paramsSubscription.unsubscribe();
    }
  }

  public irURLVinculacionDispositivo(nombreSistema: string): void {
    this.vinculacionDispositivosService.recuperarUrlVinculacionDispositivo(nombreSistema).subscribe(
      (urlVinculacion) => {
        window.location.href = urlVinculacion['url'];
      },
      (_error) => {
        this.alertService.showAlertToast(this.translateService.instant('DISPOSITIVOS.VINCULACION.ERROR'));
      }
    );
  }

  public irURLDesvincularDispositivo(nombreSistema: string): void {
    
    this.vinculacionDispositivosService.setSSOVinculacion(null, null, nombreSistema);

    this.vinculacionDispositivosService.desvincularDispositivo({}).subscribe(this.desvincularDispositivoExito.bind(this), (error) => {
      this.gestionarError(error);
    });
  }

  public vincularDispositivo() {
    if (this.ssoVinculacion.error != null) {
      this.alertService.showAlertToast(this.translateService.instant('DISPOSITIVOS.VINCULACION.ERROR'));
      this.vinculacionDispositivosService.setSSOVinculacion(null, null, null);
      this.ssoVinculacion = this.vinculacionDispositivosService.getSSOVinculacion();

    } 
    else if (this.ssoVinculacion.bind_result != null){
      const bind_result = this.ssoVinculacion.bind_result;
      if (bind_result === "unbind") {
        this.alertService.showAlertToast(this.translateService.instant('DISPOSITIVOS.VINCULACION.UNBIND'));
        this.vinculacionDispositivosService.setSSOVinculacion(null, null, null);
        this.ssoVinculacion = this.vinculacionDispositivosService.getSSOVinculacion();
      }      
      else if (bind_result === "success" || bind_result === "unchanged" ) {
        this.vinculacionDispositivosService.vincularDispositivoSuccess({}).subscribe(this.vincularDispositivoExito.bind(this), (error) => {
          this.gestionarError(error);
        });
      }
      else if (bind_result === "user_already_bound_to_another_patient") {
        this.alertService.showAlertToast(this.translateService.instant('DISPOSITIVOS.VINCULACION.USER_ALREADY_BOUND'));
        this.vinculacionDispositivosService.setSSOVinculacion(null, null, null);
        this.ssoVinculacion = this.vinculacionDispositivosService.getSSOVinculacion();
      }  
      else if (bind_result === "patient_already_bound_to_another_user"){
        this.alertService.showAlertToast(this.translateService.instant('DISPOSITIVOS.VINCULACION.PATIENT_ALREADY_BOUND'));
        this.vinculacionDispositivosService.setSSOVinculacion(null, null, null);
        this.ssoVinculacion = this.vinculacionDispositivosService.getSSOVinculacion();
      }
    }
  }

  private actualizarPerfilPaciente(perfilPaciente: Perfil): void {
    if (perfilPaciente != null) {
      this.perfilPaciente = perfilPaciente;
      this.tieneVinculoDispositivo = this.perfilService.tieneVinculoDispositivo();
    }
  }

  private vincularDispositivoExito(resp: RespuestaPeticion): void {
    if (resp && resp.executionResult) {
      this.perfilPaciente.tieneVinculoAbbott = true;
      this.perfilService.actualizarPerfilPaciente(this.perfilPaciente);
      this.alertService.lanzarExito(this.translateService.instant('DISPOSITIVOS.VINCULACION.SUCCESS'));
    } else {
      this.alertService.showAlertToast(this.translateService.instant('DISPOSITIVOS.VINCULACION.ERROR'));
    }
  }

  private desvincularDispositivoExito(resp: RespuestaPeticion): void {
    if (resp && resp.executionResult) {
      this.perfilPaciente.tieneVinculoAbbott = false;
      this.perfilService.actualizarPerfilPaciente(this.perfilPaciente);
      this.alertService.lanzarExito(this.translateService.instant('DISPOSITIVOS.DESVINCULACION.SUCCESS'));
    } 
  }

  private gestionarError(error: HttpErrorResponse): void {
    if (error.status !== 401 && error.status !== 403) {
      this.alertService.lanzarError(error.status);
    }
  }


  public confirmarDesvincular(nombreSistema: string){
    let buttons: ModalButton[] = [];

    buttons = [
      {
        texto: this.translateService.instant('DISPOSITIVOS.DESVINCULACION.DESVINCULAR'),
        type: 'button',
        accion: 'irURLDesvincularDispositivo',
        params: nombreSistema,
        cerrar: true
      }
    ];
  
  const data = {
    origen: this,
    titulo: this.translateService.instant('DISPOSITIVOS.DESVINCULACION.PREGUNTA_DESVINCULACION_SEGURO'),
    mensaje: this.translateService.instant('DISPOSITIVOS.DESVINCULACION.MENSAJE_DESVINCULACION_SEGURO') + nombreSistema,
    buttons: buttons,
    cerrar: true
  };

  this.alertService.modal.emit(data);
  }

  public avisoParaVincular(nombreSistema: string): void {
    
    let buttons: ModalButton[] = [];

    buttons = [
      {
        texto: this.translateService.instant('DISPOSITIVOS.VINCULACION.VINCULAR'),
        type: 'button',
        accion: 'irURLVinculacionDispositivo',
        params: nombreSistema,
        cerrar: true
      }
    ];
  
  const data = {
    origen: this,
    titulo: this.translateService.instant('DISPOSITIVOS.VINCULACION.TITULO_AVISO_TIEMPO_VINCULACION'),
    mensaje: this.translateService.instant('DISPOSITIVOS.VINCULACION.AVISO_TIEMPO_VINCULACION'),
    buttons: buttons,
    cerrar: true
  };

  this.alertService.modal.emit(data);
  }
}
