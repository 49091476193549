import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { AutenticacionService } from './autenticacion.service';
import { AppConfigService } from './app-config.service';
import { BehaviorSubject } from 'rxjs';
import { Perfil } from '../models/perfil.model';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { Cuidador, Pdf, TipoNotificacion } from '../models';
import { AccesoPrimeraVez } from '../models/accesoPrimeraVez.model';
import { sprintf } from 'sprintf-js';
import { ActividadesPrescritas } from '../models/actividadesPrescritas.model';

/**
 * Servicio para la gestión del perfil del paciente.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author dvila
 *·@author aarodriguezo
 *
 * @version 01.02.0300
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class PerfilService {
  private perfilUsuario: Perfil;
  private perfilPaciente: Perfil;

  //////////////////////////////////////////////////////////////////
  // Flujos a los que los clientes del servicio se pueden suscribir
  //////////////////////////////////////////////////////////////////

  /**
   * Devuelve el perfil de usuario.
   * Siempre devuelve un valor en la suscripción. Puede devolver null si aún no se ha cargado el perfil.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public perfilUsuario$: Observable<Perfil>;
  private perfilUsuarioSubject: BehaviorSubject<Perfil>;

  /**
   * Devuelve el perfil de paciente.
   * Siempre devuelve un valor en la suscripción. Puede devolver null si aún no se ha cargado el perfil.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public perfilPaciente$: Observable<Perfil>;
  private perfilPacienteSubject: BehaviorSubject<Perfil>;

  /**
   * Devuelve los cuidadores del paciente
   * Siempre devuelve un valor en la suscripción. Puede devolver null si aún no se han cargado los cuidadores.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public cuidadoresPaciente$: Observable<Cuidador[]>;
  private cuidadoresPacienteSubject: BehaviorSubject<Cuidador[]>;

  /**
   * Devuelve las instrucciones del paciente
   *
   * Este flujo se cierra automáticamente después de la primera respuesta.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public instruccionesPaciente$: Observable<Pdf>;
  private instruccionesPacienteSubject: BehaviorSubject<Pdf>;

  /**
   * Devuelve una lista con los tipos de notificación a los que está suscrito el paciente.
   * Siempre devuelve un valor en la suscripción. Puede devolver null si aún no se ha cargado el perfil.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando datos cuando haya una actualización de los mismos.
   * Este flujo nunca devolverá un error. Hay que suscribirse al flujo error$ para ello.
   */
  public tipoNotificacionesSuscritas$: Observable<TipoNotificacion[]>;
  private tipoNotificacionesSuscritasSubject: BehaviorSubject<TipoNotificacion[]>;

  /**
   * Devuelve cualquier error que se produzca durante una operación.
   *
   * Este flujo no se cierra automáticamente después de la primera respuesta,
   * sino que continuará enviando todos los errores que se produzcan.
   */
  public error$: Observable<HttpErrorResponse>;
  private errorSubject: Subject<HttpErrorResponse>;

  constructor(private http: HttpClient, private autenticacionService: AutenticacionService, private translateService: TranslateService) {
    this.perfilUsuarioSubject = new BehaviorSubject(null);
    this.perfilUsuario$ = this.perfilUsuarioSubject.asObservable();

    this.perfilPacienteSubject = new BehaviorSubject(null);
    this.perfilPaciente$ = this.perfilPacienteSubject.asObservable();

    this.cuidadoresPacienteSubject = new BehaviorSubject(null);
    this.cuidadoresPaciente$ = this.cuidadoresPacienteSubject.asObservable();

    this.tipoNotificacionesSuscritasSubject = new BehaviorSubject(null);
    this.tipoNotificacionesSuscritas$ = this.tipoNotificacionesSuscritasSubject.asObservable();

    this.instruccionesPacienteSubject = new BehaviorSubject(null);
    this.instruccionesPaciente$ = this.instruccionesPacienteSubject.asObservable();

    this.errorSubject = new Subject();
    this.error$ = this.errorSubject.asObservable();
  }

  public iniciar(): void {
    const isUsuarioAutenticado = this.autenticacionService.isUsuarioAutenticado();

    if (isUsuarioAutenticado) {
      this.recuperarPerfilUsuario().subscribe({
        next: this.actualizarPerfilUsuario.bind(this),
        error: this.gestionarError.bind(this)
      });
    }
  }

  public cargarPerfilUsuario(loginUsuario: string): void {
    this.perfilUsuario = null;
    this.autenticacionService.setLoginUsuario(loginUsuario);
    this.recuperarPerfilUsuario().subscribe({
      next: this.actualizarPerfilUsuario.bind(this),
      error: this.gestionarError.bind(this)
    });
  }

  private recuperarPerfilUsuario(): Observable<Perfil> {
    const idioma = this.translateService.currentLang;
    const usuarioLogueado = this.autenticacionService.getLoginUsuario();
    const url = sprintf(AppConfigService.urls.recuperarPerfil, usuarioLogueado, idioma);
    return this.http.get<Perfil>(url);
  }

  private actualizarPerfilUsuario(perfil: Perfil): void {

    perfil.nombre = this.quitarNombresNull(perfil.nombre);

    this.perfilUsuario = perfil;

    const currentLang = this.translateService.currentLang;
    const idioma = perfil.accesoPrimeraVez ? currentLang : perfil.localePaciente;
    this.translateService.use(idioma);
    localStorage.setItem('locale', idioma);
    this.perfilUsuarioSubject.next(perfil);

  
  }

  public cargarPerfilPaciente(loginPaciente: string): void {
    this.perfilPacienteSubject.next(null);
    this.autenticacionService.setLoginPaciente(loginPaciente);
    this.recuperarPerfilPaciente().subscribe({
      next: this.actualizarPerfilPaciente.bind(this),
      error: this.gestionarError.bind(this)
    });
  }

  private recuperarPerfilPaciente(): Observable<Perfil> {
    const idioma = this.translateService.currentLang;
    const paciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarPerfil, paciente, idioma);
    return this.http.get<Perfil>(url);
  }

  public actualizarPerfilPaciente(perfil: Perfil): void {

    perfil.nombre = this.quitarNombresNull(perfil.nombre);

    this.perfilPaciente = perfil;
    if ( this.perfilPaciente.codCentro != null){
      this.autenticacionService.setCentroPaciente(this.perfilPaciente.codCentro.toString());  
    }      
    const tipoNotificacionesSuscritas = perfil.tipoNotificacionesSuscritas.filter((notificacion) => notificacion.suscrita);
    this.tipoNotificacionesSuscritasSubject.next(tipoNotificacionesSuscritas);
    this.perfilPacienteSubject.next(perfil);

  }

  public getInstrucciones(): void {
    this.instruccionesPacienteSubject.next(null);
    this.recuperarInstrucciones().subscribe(this.actualizarInstrucciones.bind(this), this.gestionarError.bind(this));
  }

  private recuperarInstrucciones(): Observable<Pdf> {
    const idioma = this.translateService.currentLang;
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarInstruccionesPaciente, usuario, idioma);
    return this.http.get<Pdf>(url);
  }

  private actualizarInstrucciones(instrucciones: Pdf): void {
    this.instruccionesPacienteSubject.next(instrucciones);
  }

  public editarPerfil(perfil: Perfil): Observable<Perfil> {
    const loginPaciente = this.autenticacionService.getLoginPaciente();
    const loginUsuario = this.autenticacionService.getLoginUsuario();

    let locale = this.translateService.currentLang;
    if (loginUsuario === loginPaciente) {
      locale = perfil.localePaciente;
    }

    const url = sprintf(AppConfigService.urls.editarPerfil, loginPaciente, locale);
    const respuesta = this.http.post<Perfil>(url, perfil);

    const perfil$ = new Observable<Perfil>((observer) => {
      respuesta.subscribe(
        (perfilEditado: Perfil) => {
          if (loginUsuario === loginPaciente) {
            this.translateService.use(perfil.localePaciente);
            this.actualizarPerfilUsuario(perfilEditado);
            observer.next(perfilEditado);
          }
          this.actualizarPerfilPaciente(perfilEditado);
        },
        (e) => observer.error(e)
      );
    });
    return perfil$;
  }

  public editarPerfilUsuario(perfil: Perfil): Observable<Perfil> {
    const loginUsuario = this.autenticacionService.getLoginUsuario();

    const locale = this.translateService.currentLang;

    const url = sprintf(AppConfigService.urls.editarPerfil, loginUsuario, locale);
    const respuesta = this.http.post<Perfil>(url, perfil);

    const perfil$ = new Observable<Perfil>((observer) => {
      respuesta.subscribe(
        (perfilEditado: Perfil) => {
          this.translateService.use(perfil.localePaciente);
          this.actualizarPerfilUsuario(perfilEditado);
          observer.next(perfilEditado);
        },
        (e) => observer.error(e)
      );
    });
    return perfil$;
  }

  public cambiarContrasena(body: any): Observable<Object> {
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.cambiarContrasena, usuario);
    return this.http.post<Object>(url, body);
  }

  public getCuidadores(): void {
    this.cuidadoresPacienteSubject.next(null);
    this.buscarCuidadoresPaciente().subscribe({
      next: this.actualizarCuidadores.bind(this),
      error: this.gestionarError.bind(this)
    });
  }

  private buscarCuidadoresPaciente(): Observable<Cuidador[]> {
    const idioma = this.translateService.currentLang;
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarCuidadores, usuario, idioma);
    return this.http.get<Cuidador[]>(url);
  }

  private actualizarCuidadores(cuidadoresPaciente: Cuidador[]): void {
    this.cuidadoresPacienteSubject.next(cuidadoresPaciente);
  }

  public actualizarAccesoPrimeraVez(accesoPrimeraVez: AccesoPrimeraVez): Observable<Object> {
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.actualizarAccesoPrimeraVez, usuario);
    return this.http.post<Object>(url, accesoPrimeraVez);
  }

  public lanzarBotonPanico(): Observable<boolean> {
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.lanzarBotonPanico, usuario);
    return this.http.post<boolean>(url, []);
  }

  public esUsuarioCuidador(): boolean {
    return (
      (this.perfilUsuario != null && this.perfilUsuario.pacientes.length > 0) ||
      this.autenticacionService.getLoginPaciente() !== this.autenticacionService.getLoginUsuario()
    );
  }

  public tieneNasi(): boolean {
    return this.perfilPaciente != null && this.perfilPaciente.nasi != null;
  }

  public tieneVinculoDispositivo(): boolean {
    return this.perfilPaciente.tieneVinculoAbbott;
  }

  private gestionarError(errorResponse: HttpErrorResponse): void {
    this.errorSubject.next(errorResponse);
  }

  public detener(): void {
    this.perfilUsuario = null;
    this.perfilUsuarioSubject.next(null);
    this.perfilPacienteSubject.next(null);
    this.tipoNotificacionesSuscritasSubject.next(null);
  }

  public obtenerActividadesPrescritas(): ActividadesPrescritas {
    if (this.perfilPaciente.protocolosActivos.length === 0) {
      return null;
    } else {
      if (this.perfilPaciente.protocolosActivos[0].idProtocoloMaestro === 4) {
        return null;
      } else {
        return this.perfilPaciente.protocolosActivos[0].actividadesPrescritas;
      }
    }
  }

  public obtenerProtocoloActivo(): number {
    if (this.perfilPaciente.protocolosActivos.length === 0) {
      return null;
    } else {
      return this.perfilPaciente.protocolosActivos[0].idProtocoloMaestro;
    }
  }

  private quitarNombresNull(nombre: string): string {
    let nombreNuevo: string = nombre;

    while (nombreNuevo.includes("null")) {
        nombreNuevo = nombreNuevo.replace("null", "");
    }

    return nombreNuevo;
  }


}
