import { Component, OnDestroy, HostListener, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SimplebarAngularComponent } from 'simplebar-angular';
import {
  AlertService,
  AppConfigService,
  CalendarioService,
  CuestionariosService,
  GestionActCuestionarioService
} from 'src/app/core/services';
import { CuestionarioRespuesta, ItemResumenCuestionario, Plantilla, ValoracionCuestionario, Respuesta, LocaleTexto } from '../../../../core/models';

declare var $: any;

/**
 * Componente para el registro de un cuestionario.
 *
 * @author lreverendo
 * @author dvila
 *
 * @version 01.02.0050
 * @since 01.02.0000
 */
@Component({
  selector: 'app-registro-cuestionario',
  templateUrl: './registro-cuestionario.component.html',
  styleUrls: ['../../cuestionarios.component.css', './registro-cuestionario.component.css']
})
export class RegistroCuestionarioComponent implements OnDestroy {
  @ViewChild('preguntas') preguntas: SimplebarAngularComponent;
  public pagina = 0;
  public tipo: String;
  public titulo: LocaleTexto[];
  public items: ItemResumenCuestionario[] = [];
  public plantilla: Plantilla = new Plantilla();
  public valoraciones: ValoracionCuestionario[];
  public respuestas: {} = {};
  public disabled: Boolean = true;

  public avisoCerrar: Boolean = false;

  public desplazamientoMedioTramo: number;
  public medioTramo: {
    primero: number;
    ultimo: number;
  };

  private sub: Subscription;

  constructor(
    private alertService: AlertService,
    private calendarioService: CalendarioService,
    private cuestionariosService: CuestionariosService,
    private gestionActCuestionarioService: GestionActCuestionarioService,
    private translateService: TranslateService
  ) {
    // Subscripción en el evento registroCuestionario.
    this.sub = this.gestionActCuestionarioService.registroCuestionario.subscribe((data) => {
      this.pagina = 0;
      this.disabled = true;
      this.respuestas = {};
      this.avisoCerrar = false;
      this.tipo = data.tipoOntologia;
      this.titulo = data.titulo;
      this.plantilla = data.plantilla;
      this.valoraciones = data.valoraciones;
      this.items = [];

      const tam: number = window.innerWidth;
      this.calcularDesplazamientoMedio(tam);
      this.gestionarBurbujasMedio();

      $('#registro-cuestionario').modal({ backdrop: 'static', keyboard: false });

      if ($('#modal').hasClass('show')) {
        // Se abre el modal.
        $('#modal').modal('hide');
      }

      // Se abre el modal.
      $('#registro-cuestionario').modal('show');

      $('#registro-cuestionario').on('shown.bs.modal', function () {
        const instance = $(this);
        setTimeout(function () {
          const input = instance.closest('#registro-cuestionario').find('input')[0];
          if (input) {
            input.focus();
          }
        }, 180);
      });
    });
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    this.sub.unsubscribe();
  }

  public todasCompletas(): boolean {
    const todasCompletas: boolean = Object.keys(this.respuestas).length === this.plantilla.preguntas.length;
    return todasCompletas;
  }

  public agregarRespuesta(idPregunta, idRespuesta): void {
    this.respuestas[idPregunta] = idRespuesta;
    // Retardo de 200 msegundos
    setTimeout(() => {
      this.asociarPregunta(idPregunta);
    }, 200);
  }

  public asociarPregunta(idPregunta): void {
    let nombrePregunta: String;
    let descRespuesta: String = '';
    let respuestas: Respuesta[];
    const pregunta = this.plantilla.preguntas[this.pagina];

    switch (this.getCurrentIdioma()) {
      case 'gl':
        nombrePregunta = pregunta.nombre_gl;
        respuestas = pregunta.respuestasAsociadas;
        if (respuestas != null) {
          for (const respuesta of respuestas) {
            if (respuesta.id === this.respuestas[idPregunta]) {
              descRespuesta = respuesta.descripcion_gl;
            }
          }
        }
        break;
      case 'en':
        nombrePregunta = pregunta.nombre_en ? pregunta.nombre_en : pregunta.nombre_gl;
        respuestas = pregunta.respuestasAsociadas;
        if (respuestas != null) {
          for (const respuesta of respuestas) {
            if (respuesta.id === this.respuestas[idPregunta]) {
              descRespuesta = respuesta.descripcion_en ? respuesta.descripcion_en : respuesta.descripcion_gl;
            }
          }
        }
        break;
      default:
        nombrePregunta = pregunta.nombre;
        respuestas = pregunta.respuestasAsociadas;
        if (respuestas != null) {
          for (const respuesta of respuestas) {
            if (respuesta.id === this.respuestas[idPregunta]) {
              descRespuesta = respuesta.descripcion;
            }
          }
        }
        break;
    }

    if (descRespuesta === '') {
      // idRespuesta es la propia respuesta
      descRespuesta = this.respuestas[idPregunta];
    }

    this.items[this.pagina] = {
      pregunta: {
        id: idPregunta,
        nombre: nombrePregunta
      },
      respuesta: {
        id: this.respuestas[idPregunta],
        descripcion: descRespuesta
      }
    };

    if (this.plantilla.preguntas.length === Object.keys(this.respuestas).length) {
      this.disabled = false;
    }

    this.irPagina(this.pagina + 1);
  }

  public cerrar(): void {
    // Se muestra un aviso antes de cerrar.
    if (this.avisoCerrar) {
      // Si el aviso ya estaba abierto, se cierra el modal.
      $('#registro-cuestionario').modal('hide');
    }
    this.avisoCerrar = true;
  }

  public enviarCuestionario(): void {
    const body: CuestionarioRespuesta = {
      origen: AppConfigService.origenDatos,
      respuestas: this.respuestas,
      plantilla: this.plantilla,
      codigoCuestionario: this.tipo,
      titulo: this.titulo,
      valoraciones: this.valoraciones
    };
    this.cuestionariosService.enviarCuestionario(body).subscribe(
      () => {
        // Se cierra el modal.
        $('#registro-cuestionario').modal('hide');
        this.calendarioService.actualizarCalendario.emit();
        this.alertService.lanzarExito(this.translateService.instant('CUESTIONARIOS.NOTIFICACION.SUCCESS'));
      },
      (error) => {
        // Se cierra el modal.
        $('#registro-cuestionario').modal('hide');
        this.alertService.lanzarError(error.status);
      }
    );
  }

  public irPagina(pagina: number): void {
    this.pagina = pagina;
    this.gestionarBurbujasMedio();
    if (this.preguntas) {
      this.preguntas.SimpleBar.getScrollElement().scrollTop = 0;
    }
  }

  public irPregunta(idPregunta: number): void {
    for (let i = 0; i < this.items.length; i++) {
      if (this.items[i].pregunta.id === idPregunta) {
        this.irPagina(i);
      }
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event): void {
    const tam: number = event.target.innerWidth;
    this.calcularDesplazamientoMedio(tam);
    this.gestionarBurbujasMedio();
  }

  private calcularDesplazamientoMedio(tamPantalla: number): void {
    // El desplazamientoMedioTramo se refiere al número de burbujas por atrás y por delante del mínimo, que es 3.
    const numPreguntas: number = this.plantilla.preguntas.length;
    this.desplazamientoMedioTramo = (numPreguntas - 3) / 2;

    if (tamPantalla < 418) {
      if (numPreguntas > 3) {
        this.desplazamientoMedioTramo = 0;
      }
    } else if (tamPantalla < 576) {
      if (numPreguntas > 5) {
        this.desplazamientoMedioTramo = 1;
      }
    } else if (tamPantalla < 768) {
      if (numPreguntas > 7) {
        this.desplazamientoMedioTramo = 2;
      }
    } else if (tamPantalla < 992) {
      if (numPreguntas > 9) {
        this.desplazamientoMedioTramo = 3;
      }
    } else {
      if (numPreguntas > 13) {
        this.desplazamientoMedioTramo = 5;
      }
    }
  }

  private gestionarBurbujasMedio(): void {
    const numPreguntas: number = this.plantilla.preguntas.length - 1;

    const atras = Math.round(this.desplazamientoMedioTramo);
    const delante = Math.floor(this.desplazamientoMedioTramo);

    let primero = this.pagina - atras;
    let ultimo = this.pagina + delante;

    let auxPrimero = primero;
    let auxUltimo = ultimo;

    if (primero < 1) {
      auxUltimo = ultimo + -primero + 1;
      auxPrimero = 1;
    }

    if (ultimo > numPreguntas - 1) {
      auxPrimero = primero - (ultimo - numPreguntas) - 1;
      auxUltimo = numPreguntas - 1;
    }

    primero = auxPrimero;
    ultimo = auxUltimo;

    // Primero y ultimo del tramo teniendo en cuenta el deplazamiento.
    this.medioTramo = {
      primero: primero,
      ultimo: ultimo
    };

    setTimeout(function () {
      $('#respuestaNum').trigger('focus');
      $('#respuestaTexto').trigger('focus');
    }, 100);
  }

  public getCurrentIdioma(): string {
    // Se recupera el idioma actual.
    return this.translateService.currentLang;
  }
}
