import { Component, OnDestroy } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDatepickerI18n, CustomNgbDateParserFormatter } from '../../../../shared/directives/custom-datepicker/custom-datepicker';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { formatISO, parseISO, format } from 'date-fns';

import { ActividadesService, AlertService, CalendarioService } from '../../../../core/services';
import { Actividad, LocaleTexto } from '../../../../core/models';
import { LocaleTextoPipe } from '../../../../shared/pipes/locale-texto/locale-texto';

declare var $: any;

/**
 * Componente para la creación de actividades personales.
 *
 * @author lreverendo
 * @author fjsalgado
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Component({
  selector: 'app-actividad-personal',
  templateUrl: './actividad-personal.component.html',
  styleUrls: ['../../../perfil/perfil.component.css', './actividad-personal.component.css'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }
  ]
})
export class ActividadPersonalComponent implements OnDestroy {
  public forma: FormGroup;
  public disabled = false;
  public actividad: Actividad = new Actividad();
  public editar: boolean;

  private hoy: Object;
  private subs: Subscription[] = [];
  private todoElDia = false;
  private timer: any;
  private checkBoxClick: boolean;
  private mensajeExito: string;

  public mask: Array<string | RegExp> = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];

  constructor(
    private actividadesService: ActividadesService,
    private alertService: AlertService,
    private calendarioService: CalendarioService,
    private translateService: TranslateService,
    private localeTexto: LocaleTextoPipe
  ) {
    // Se calcula la fecha actual.
    const fecha = new Date();
    fecha.setHours(0, 0, 0, 0);
    this.hoy = {
      year: fecha.getFullYear(),
      month: fecha.getMonth() + 1,
      day: fecha.getDate()
    };

    // Inicialización del formulario.
    this.forma = new FormGroup(
      {
        titulo: new FormControl('', Validators.required),
        descripcion: new FormControl('', [Validators.required, Validators.maxLength(200)]),
        fecha: new FormControl(this.hoy),
        horaIni: new FormControl({ value: '', disabled: false }, [
          Validators.required,
          Validators.pattern('^([01]?[0-9]|2[0-3]):[0-5]?[0-9]$')
        ]),
        horaFin: new FormControl({ value: '', disabled: false }, [
          Validators.required,
          Validators.pattern('^([01]?[0-9]|2[0-3]):[0-5]?[0-9]$')
        ]),
        todoElDia: new FormControl(false)
      },
      this.fechasCorrectas
    );

    this.forma.get('horaIni').valueChanges.subscribe(this.changeRegex.bind(this));
    this.forma.get('horaFin').valueChanges.subscribe(this.changeRegex.bind(this));

    // Subscripción al evento crearActividadPersonal.
    this.subs.push(this.calendarioService.crearActividadPersonal.subscribe(this.abrirModalCrear.bind(this)));

    // Subscripción al evento editarActividadPersonal.
    this.subs.push(this.calendarioService.editarActividadPersonal.subscribe(this.abrirModalEditar.bind(this)));
  }

  public ngOnDestroy(): void {
    // Baja a la subscripción.
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }

  private changeRegex(value: string): void {
    if (value.charAt(0) === '2') {
      this.mask[1] = new RegExp('[0-3]');
    } else {
      this.mask[1] = new RegExp('\\d');
    }
  }

  private abrirModalCrear(): void {
    this.editar = false;
    this.mensajeExito = this.translateService.instant('CALENDARIO.PERSONAL.CREAR.NOTIFICACION.SUCCESS');

    // Se calcula la fecha actual.
    const fecha = new Date();
    fecha.setHours(0, 0, 0, 0);
    this.hoy = {
      year: fecha.getFullYear(),
      month: fecha.getMonth() + 1,
      day: fecha.getDate()
    };

    this.limpiar();

    // Se abre el modal.
    $('#personal').modal('show');
    $('#personal').on('shown.bs.modal', function () {
      setTimeout(function () {
        $('#titulo').trigger('focus');
      }, 180);
    });
  }

  private abrirModalEditar(params): void {
    this.editar = true;
    this.mensajeExito = this.translateService.instant('CALENDARIO.PERSONAL.EDITAR.NOTIFICACION.SUCCESS');

    this.actividad = params.actividad;
    this.limpiar();

    // Se abre el modal.
    $('#personal').modal('show');
    $('#personal').on('shown.bs.modal', function () {
      setTimeout(function () {
        $('#titulo').trigger('focus');
      }, 180);
    });
  }

  private gestionarError(error: HttpErrorResponse): void {
    // Se cierra el modal.
    $('#personal').modal('hide');

    this.disabled = false;
    this.alertService.lanzarError(error.status);
  }

  private fechasCorrectas(forma: FormGroup): { [s: string]: boolean } {
    const horaIni = forma.controls['horaIni'].value.split(':')[0];
    const minIni = forma.controls['horaIni'].value.split(':')[1];
    const horaFin = forma.controls['horaFin'].value.split(':')[0];
    const minFin = forma.controls['horaFin'].value.split(':')[1];
    const fecha = forma.controls['fecha'].value;

    const fechaIni = new Date(fecha.year, fecha.month - 1, fecha.day, horaIni, minIni);
    const fechaFin = new Date(fecha.year, fecha.month - 1, fecha.day, horaFin, minFin);

    // Método que comprueba que la fecha de inicio sea menor a la de fin.
    if (fechaIni.getTime() > fechaFin.getTime()) {
      return {
        noCorrectas: true
      };
    }
    return null;
  }

  private gestionarExitoCambio(): void {
    // Se cierra el modal.
    $('#personal').modal('hide');

    // Limpiamos el formulario.
    this.limpiar();

    // Se llama al evento actualizarCalendario.
    this.calendarioService.actualizarCalendario.emit();

    this.disabled = false;

    this.actividad = new Actividad();

    // Se muestra mensaje de éxito.
    this.alertService.lanzarExito(this.mensajeExito);
  }

  public todoeldia(): void {
    // Método para habilitar o deshabilitar las horas según el checkBox todoElDia.
    this.timer = 0;
    this.checkBoxClick = false;
    const delay = 200;

    // Se establece un timeout, si en 200 milisegundos no se recibe respuesta continua.
    this.timer = setTimeout(() => {
      if (!this.checkBoxClick) {
        if (!this.todoElDia) {
          this.forma.controls['horaIni'].reset({
            value: '00:00',
            disabled: true
          });
          this.forma.controls['horaFin'].reset({
            value: '00:00',
            disabled: true
          });
        } else {
          this.forma.controls['horaIni'].reset({ value: '', disabled: false });
          this.forma.controls['horaFin'].reset({ value: '', disabled: false });
        }
        this.todoElDia = !this.todoElDia;
        this.checkBoxClick = false;
      }
    }, delay);
  }

  public dobleClick(): void {
    // Limpia el timeout activo.
    clearTimeout(this.timer);
  }

  public guardarCambios(): void {
    this.disabled = true;
  
    const fecha = this.forma.controls['fecha'].value;
  
    const fechaIni = new Date(fecha.year, fecha.month - 1, fecha.day);
    const fechaFin = new Date(fecha.year, fecha.month - 1, fecha.day);
  
    if (this.todoElDia) {
      fechaIni.setHours(0, 0, 0, 0);
      fechaFin.setHours(0, 0, 0, 0);
      fechaFin.setDate(fechaFin.getDate() + 1);
    } else {
      const horaIni = this.forma.controls['horaIni'].value.split(':')[0];
      const minIni = this.forma.controls['horaIni'].value.split(':')[1];
      const horaFin = this.forma.controls['horaFin'].value.split(':')[0];
      const minFin = this.forma.controls['horaFin'].value.split(':')[1];
      fechaIni.setHours(horaIni, minIni, 0, 0);
      fechaFin.setHours(horaFin, minFin, 0, 0);
    }
  
    const descripcion = this.forma.value.descripcion.replace(/\n/g, '<br>');
  
    const tituloCastellano: LocaleTexto = {
      locale: 'es',
      texto: this.forma.value.titulo
    };
  
    const tituloGallego: LocaleTexto = {
      locale: 'gl',
      texto: this.forma.value.titulo
    };
  
    const tituloIngles: LocaleTexto = {
      locale: 'en',
      texto: this.forma.value.titulo
    };
  
    const descripcionCastellano: LocaleTexto = {
      locale: 'es',
      texto: descripcion
    };
  
    const descripcionGallego: LocaleTexto = {
      locale: 'gl',
      texto: descripcion
    };
  
    const descripcionIngles: LocaleTexto = {
      locale: 'en',
      texto: descripcion
    };
  
    this.actividad.titulo = [tituloCastellano, tituloGallego, tituloIngles];
    this.actividad.descripcion = [descripcionCastellano, descripcionGallego, descripcionIngles];
  
    this.actividad.fechaInicioSinMargen = formatISO(fechaIni);
    this.actividad.fechaFinalSinMargen = formatISO(fechaFin);
  
    if (this.editar) {
      this.actividadesService.editarActividadPersonal(this.actividad).subscribe({
        next: this.gestionarExitoCambio.bind(this),
        error: this.gestionarError.bind(this)
      });
    } else {
      this.actividadesService.crearActividadPersonal(this.actividad).subscribe({
        next: this.gestionarExitoCambio.bind(this),
        error: this.gestionarError.bind(this)
      });
    }
  }

  public limpiar(): void {
    if (this.editar) {
      const locale = this.translateService.currentLang;
      const fechaActIni = parseISO(this.actividad.fechaInicioSinMargen);
      const fecha = {
        year: fechaActIni.getFullYear(),
        month: fechaActIni.getMonth() + 1,
        day: fechaActIni.getDate()
      };
      const horaIni = format(fechaActIni, 'HH:mm');
      const horaFin = format(parseISO(this.actividad.fechaFinalSinMargen), 'HH:mm');
  
      this.todoElDia = this.actividad.todoElDia;
  
      // Se recogen los datos y se inicializa el formulario.
      this.forma.reset({
        titulo: this.localeTexto.transform(this.actividad.titulo, locale),
        descripcion: this.localeTexto.transform(this.actividad.descripcion, locale),
        fecha: fecha,
        horaIni: { value: horaIni, disabled: this.actividad.todoElDia },
        horaFin: { value: horaFin, disabled: this.actividad.todoElDia },
        todoElDia: this.actividad.todoElDia
      });
    } else {
      this.todoElDia = false;
      this.forma.reset({
        titulo: '',
        descripcion: '',
        fecha: this.hoy,
        horaIni: { value: '', disabled: false },
        horaFin: { value: '', disabled: false },
        todoElDia: false
      });
    }
  }
}
