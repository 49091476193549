import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AutenticacionService } from './autenticacion.service';
import { PerfilService } from './perfil.service';
import { Biomedida, Dispositivo, Visit } from '../models';
import { AppConfigService } from './app-config.service';
import { sprintf } from 'sprintf-js';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { startOfDay } from 'date-fns';


/**
 * Servicio para la gestión de las biomedidas.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Injectable({ providedIn: 'root' })
export class BiomedidasService {
  constructor(private http: HttpClient, private autenticacionService: AutenticacionService, private perfilService: PerfilService) {}

  public getBiomedidasHistorico(fechaIni: String, fechaFin: String, tipos: String[]): Observable<Biomedida[]> {
    fechaIni = fechaIni.split('T')[0];
    fechaFin = fechaFin.split('T')[0];
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarBiomedidasHistorico, dniPaciente, fechaIni, fechaFin, tipos);
    return this.http.get<Biomedida[]>(url);
  }

  public getBiomedidas(ids: String[]): Observable<Biomedida[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarBiomedidas, dniPaciente, ids);
    return this.http.get<Biomedida[]>(url);
  }

  public getTalla(): Observable<Biomedida[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarTalla, dniPaciente);
    return this.http.get<Biomedida[]>(url);
  }

  public getDispositivos(): Observable<Dispositivo[]> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.recuperarDispositivos, dniPaciente);
    return this.http.get<Dispositivo[]>(url);
  }

  public enviarBiomedida(body: any): Observable<Biomedida> {
    const dniPaciente = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.enviarBiomedida, dniPaciente);
    return this.http.post<Biomedida>(url, body);
  }


  public getVisitasINR(): Observable<Visit[]> {
    const usuario = this.autenticacionService.getLoginPaciente();
    const url = sprintf(AppConfigService.urls.obtenerVisitINR, usuario);
    return this.http.get<Visit[]>(url);
  }

  public puedeMandarINRporVisita(fecha: Date): Observable<boolean> {
    // Validar si el protocolo activo es distinto de 26
    if (this.perfilService.obtenerProtocoloActivo() !== 26) {
      // Si no es 26, inmediatamente retornar true
      return of(true);
    }
  
    // Solo llegamos aquí si el protocolo activo es 26
    return this.getVisitasINR().pipe(
      map((visitasINR) => {
        if (!visitasINR || visitasINR.length === 0) {
          // Si no hay visitas registradas, se puede mandar INR
          return true;
        }
  
        // Truncar la fecha a la medianoche para comparar solo día, mes y año
        const fechaTruncada = startOfDay(fecha).getTime();
  
        // Buscar la visita con la misma fecha truncada
        const visita = visitasINR.some((v) => startOfDay(new Date(v.fechaVisita)).getTime() === fechaTruncada);
  
        // Si se encuentra la visita, no se puede mandar INR; de lo contrario, sí
        return !visita;
      })
    );
  }
  
  
}
