import { RouterModule, Routes } from '@angular/router';
import {
  BiomedidasComponent,
  CalendarioComponent,
  PrincipalExternoComponent,
  CuestionariosComponent,
  ElegirCuentaComponent,
  LoginComponent,
  MedicacionComponent,
  NotificacionesComponent,
  PerfilComponent,
  PrimerAccesoComponent,
  PrincipalComponent,
  SoporteTecnicoComponent,
  MensajeriaComponent,
  DispositivosComponent
} from './pages/index';
import { ErrorGuardService, AuthGuardService, ExternoAuthGuardService, LoginGuardService } from './core/guards';
import { ErrorAlmacenamientoComponent } from './shared/components/errorAlmacenamiento/errorAlmacenamiento.component';

const APP_ROUTES: Routes = [
  {
    path: 'error',
    component: ErrorAlmacenamientoComponent,
    canActivate: [ErrorGuardService]
  },
  { path: 'login', component: LoginComponent, canActivate: [LoginGuardService] },
  {
    path: 'primerAcceso',
    component: PrimerAccesoComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'elegir',
    component: ElegirCuentaComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'externo',
    component: PrincipalExternoComponent,
    children: [
      {
        path: 'calendario',
        component: CalendarioComponent,
        canActivate: [ExternoAuthGuardService]
      }
    ]
  },
  {
    path: '',
    component: PrincipalComponent,
    children: [
      {
        path: 'perfil',
        component: PerfilComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'calendario',
        component: CalendarioComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'dispositivos',
        component: DispositivosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'biomedidas',
        component: BiomedidasComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'cuestionarios',
        component: CuestionariosComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'soporte',
        component: SoporteTecnicoComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'medicacion',
        component: MedicacionComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'notificaciones',
        component: NotificacionesComponent,
        canActivate: [AuthGuardService]
      },
      {
        path: 'mensajeria',
        component: MensajeriaComponent,
        canActivate: [AuthGuardService]
      },
      { path: '**', pathMatch: 'full', redirectTo: 'calendario' }
    ]
  },
  { path: '**', pathMatch: 'full', redirectTo: 'login' }
];

export const APP_ROUTING = RouterModule.forRoot(APP_ROUTES, { useHash: true });
