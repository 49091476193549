import { Component, Input, OnChanges } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { NgbDatepickerI18n, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { CustomDatepickerI18n, CustomNgbDateParserFormatter } from '../../../../shared/directives/custom-datepicker/custom-datepicker';
import { AlertService, BiomedidasService, LoaderService } from '../../../../core/services';
import { Biomedida, Dispositivo, VariableClinica } from '../../../../core/models';
import { EChartOption } from 'echarts';
import { Fecha } from '../../../../core/models/fecha.model';
import { TranslateService } from '@ngx-translate/core';
import { differenceInMonths } from 'date-fns';


/**
 *  Componente para el historial de biomedidas.
 *
 * @author lreverendo
 * @author aarodriguezo
 *
 * @version 01.02.0350
 * @since 01.02.0000
 */
@Component({
  selector: 'app-historial-biomedida [dispositivo]',
  templateUrl: 'historial-biomedida.component.html',
  styleUrls: ['../../biomedidas.component.css', './historial-biomedida.component.css'],
  providers: [
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    { provide: NgbDateParserFormatter, useClass: CustomNgbDateParserFormatter }
  ]
})
export class HistorialBiomedidaComponent implements OnChanges {
  @Input() dispositivo: Dispositivo;

  public chartOption: EChartOption;
  public errorFechas = false;
  public labels: Number[] = [];
  public datasets: EChartOption.SeriesLine[] = [];
  public opciones: EChartOption;
  public fechas: Number[] = [];
  private datos: Biomedida[];
  private variablesclinicas: VariableClinica[];
  private tipos: string[] = [];
  private coloresLineas = ['#b94d8d', '#ec8c1b', '#4f75d5'];
  private tiposVariableClinica: string[];
  public fechaFin: Fecha;
  public fechaIni: Fecha;

  private biomedidas: {
    tipo: String;
    formato: String;
    listaBiomedidas: Biomedida[];
  }[] = [];

  constructor(
    public loader: LoaderService,
    private alertService: AlertService,
    private biomedidasService: BiomedidasService,
    private datePipe: DatePipe,
    private translateService: TranslateService
  ) {}

  public ngOnChanges(): void {
    this.calcularFechaActual();

    // Se recogen las variables clinicas.
    this.variablesclinicas = this.dispositivo.sensor.variablesclinicasobj;

    // Cargamos el tipo de variable clínica
    this.gestionarTipos();

    // Cargamos los datos.
    this.fechaChange();
  }

  public isNaN(valor: number): boolean {
    return Number.isNaN(valor);
  }

  public gestionarTipos() {
    this.tiposVariableClinica = [];

    this.variablesclinicas.forEach((variableClinica) => {
      this.tiposVariableClinica.push(variableClinica.alias);
    });
  }

  public fechaChange(): void {
    // Se cargan las biomedidas con fecha actividad entre las fechas seleccionadas.

    const fechaIni = new Date(this.fechaIni.year, this.fechaIni.month - 1, this.fechaIni.day);
    const fechaFin = new Date(this.fechaFin.year, this.fechaFin.month - 1, this.fechaFin.day);

    if (new Date(fechaIni) <= new Date(fechaFin)) {
      // Si la fecha inicial es menor que la final se cargan las biomedidas entre esas fechas.
      this.limpiar();
      this.errorFechas = false;

      this.biomedidasService
        .getBiomedidasHistorico(
          this.datePipe.transform(fechaIni, 'yyyy-MM-dd'),
          this.datePipe.transform(fechaFin, 'yyyy-MM-dd'),
          this.tiposVariableClinica
        )
        .subscribe({
          next: this.gestionarDatos.bind(this),
          error: this.gestionarError.bind(this)
        });
    } else {
      // Si la fecha inicial es mayor que la final se muestra un mensaje de error.
      this.errorFechas = true;
    }
  }

  private gestionarDatos(biomedidas: Biomedida[]): void {
    this.datos = biomedidas;
    this.encontrarBiomedidasTipo();
    this.crearLabels();
    this.crearDataSet();

    if (this.labels.length > 0) {
      this.crearGrafica();
    }
  }

  private gestionarError(error: HttpErrorResponse): void {
    this.alertService.lanzarError(error.status);
  }

  private calcularFechaActual(): void {
    // Se calcula la fecha actual.
    const fecha = new Date();
    this.fechaFin = { year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: fecha.getDate() };
    this.fechaIni = { year: fecha.getFullYear(), month: fecha.getMonth() + 1, day: 1 };
  }

  private encontrarBiomedidasTipo(): void {
    // Se buscan las biomedidas correspondientes al dispositivo.
    const biomedidas: {
      tipo: string;
      formato: string;
      listaBiomedidas: Biomedida[];
    }[] = [];
    const tipos: string[] = [];
    for (const variableClinica of this.variablesclinicas) {
      let listaBiomedidas: Biomedida[] = [];
      const formato = variableClinica.formato;
      const tipo = variableClinica.alias;
      listaBiomedidas = this.datos.filter((b) => b.tipo === tipo);

      biomedidas.push({
        tipo: tipo,
        formato: formato,
        listaBiomedidas: listaBiomedidas
      });

      tipos.push(tipo);
    }

    this.tipos = tipos;
    this.biomedidas = biomedidas;
  }

  private crearLabels(): void {
    // Se crean las etiquetas de la tabla y el gráfico.
    let fechas: Number[] = [];

    for (let i = 0; i < this.biomedidas.length; i++) {
      for (let o = 0; o < this.biomedidas[i].listaBiomedidas.length; o++) {
        const fecha = this.biomedidas[i].listaBiomedidas[o].fechaActividad;
        if (fechas.indexOf(fecha) === -1) {
          fechas.push(this.biomedidas[i].listaBiomedidas[o].fechaActividad);
        }
      }
    }

    fechas = fechas.sort((n1, n2) => {
      if (n1 > n2) {
        return 1;
      }
      if (n1 < n2) {
        return -1;
      }
      return 0;
    });

    this.labels = fechas;
  }

  private crearDataSet(): void {
    const datos: EChartOption.SeriesLine[] = [];

    for (let i = 0; i < this.biomedidas.length; i++) {
      const valores: any[][] = [];
      for (let u = 0; u < this.labels.length; u++) {
        let valor: Number = NaN;
        let fechaValor: Number;
        for (let o = 0; o < this.biomedidas[i].listaBiomedidas.length; o++) {
          const biomedida = this.biomedidas[i].listaBiomedidas[o];
          const fecha = biomedida.fechaActividad;
          if (biomedida.fechaActividad === this.labels[u]) {
            valor = Number(biomedida.valor);
            fechaValor = fecha;
          }
        }
        valores.push([fechaValor, valor]);
      }

      datos.push({
        name: this.translateService.instant('BIOMEDIDAS.VARIABLES.' + this.biomedidas[i].tipo + '.alias'),
        type: 'line',
        data: valores,
        lineStyle: {
          color: this.coloresLineas[i]
        },
        itemStyle: {
          color: this.coloresLineas[i]
        },
        symbolSize: 10,
        smooth: true,
        formato: this.biomedidas[i].formato
      });
    }

    this.datasets = datos;
  }

  private crearGrafica(): void {
    const _this = this;
    this.opciones = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        data: this.tipos
      },
      grid: {
        left: '5%',
        bottom: '55px',
        top: '48px',
        containLabel: true
      },
      xAxis: {
        type: 'time',
        axisLabel: {
          formatter: function (params) {
            const lastYear = new Date(_this.labels[_this.labels.length - 1].valueOf()).getFullYear();
            const firstYear = new Date(_this.labels[0].valueOf()).getFullYear();
            if (lastYear - firstYear > 0) {
              if (_this.translateService.currentLang === 'en') {
                return _this.datePipe.transform(params, 'MM-dd\nyyyy');
              } else {
                return _this.datePipe.transform(params, 'dd-MM\nyyyy');
              }
            }
            if (differenceInMonths(new Date(_this.labels[_this.labels.length - 1].valueOf()), new Date(_this.labels[0].valueOf())) > 0) {
              if (_this.translateService.currentLang === 'en') {
                return _this.datePipe.transform(params, 'MM-dd\n');
              } else {
                return _this.datePipe.transform(params, 'dd-MM\n');
              }
            }
            if (_this.translateService.currentLang === 'en') {
              return _this.datePipe.transform(params, 'MM-dd\nHH:mm');
            } else {
              return _this.datePipe.transform(params, 'dd-MM\nHH:mm');
            }
          }
        },
        axisPointer: {
          snap: true,
          lineStyle: {
            color: '#55b0e5',
            opacity: 0.5,
            width: 5
          },
          label: {
            show: true,
            formatter: function (params) {
              const lastYear = new Date(_this.labels[_this.labels.length - 1].valueOf()).getFullYear();
              const firstYear = new Date(_this.labels[0].valueOf()).getFullYear();
              if (lastYear - firstYear > 0) {
                if (_this.translateService.currentLang === 'en') {
                  return _this.datePipe.transform(params.value, 'MM-dd-yyyy\nHH:mm');
                } else {
                  return _this.datePipe.transform(params.value, 'dd-MM-yyyy\nHH:mm');
                }
              }
              if (_this.translateService.currentLang === 'en') {
                return _this.datePipe.transform(params.value, 'MM-dd\nHH:mm');
              } else {
                return _this.datePipe.transform(params.value, 'dd-MM\nHH:mm');
              }
            },
            backgroundColor: '#55b0e5',
          },
          handle: {
            show: true,
            shadowColor: '#fff',
            color: "#55b0e5",
            margin: 60
          }
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value}\n'
        },
        scale: true,
        zlevel: 1000
      },
      series: this.datasets,
      useUTC: true
    };
  }

  private limpiar(): void {
    // Borrado de los datos.
    this.opciones = {};
  }



}
